import { B256Address, Bech32Address, BN } from "fuels";
import { parseBN, PRECISION } from "./format";
import { AssetContracts } from "./contracts";
import { IAssetContext } from "../types/fluid.types";

export interface TrovePosition {
  owner: string;
  debt: BN;
  coll: BN;
  debt_reward: BN;
  coll_reward: BN;
}

export function getTroveCollateralRatio(trove: TrovePosition, price: BN) {
  return price
    .mul(trove.coll.add(trove.coll_reward.div(PRECISION)))
    .div(trove.debt.add(trove.debt_reward));
}

export interface AssetConfig {
  name: string;
  symbol: string;
  color: string;
  boxColor: string;
  imageUrl: string;
}

export const createAssetContracts = (
  index: number,
  assetConfig: AssetConfig,
  contracts: JSON
): AssetContracts => {
  const config = assetConfig;
  const contractData = (contracts as any).asset_contracts[index];

  return {
    asset_contract: contractData.asset_contract as Bech32Address,
    assetId: contractData.asset_id as string,
    assetName: config.name,
    assetSymbol: config.symbol,
    color: config.color,
    boxColor: config.boxColor,
    imageUrl: config.imageUrl,
    oracle: contractData.oracle as Bech32Address,
    troveManager: contractData.trove_manager as Bech32Address,
    pythContract: contractData.pyth_contract as Bech32Address,
    oracleImplementation: contractData.oracle_implementation_id as B256Address,
    troveManagerImplementation:
      contractData.trove_manager_implementation_id as B256Address,
    disabled: contractData.disabled,
  };
};

export function getAssetApr(asset: IAssetContext, fuelPrice: BN) {
  if (asset.symbol === "ETH" || asset.symbol === "FUEL") {
    // Get total value locked for this asset
    const tvl = asset.totalInActivePool.mul(asset.price).div(PRECISION);

    if (tvl.isZero()) return "0%";

    // Daily FUEL reward for this asset (40,000 FUEL total)
    // 55% for FUEL (22,000) and 45% for ETH (18,000)
    const dailyFuelReward = new BN(
      asset.symbol === "FUEL" ? 22_000 : 18_000
    ).mul(PRECISION);

    // Value of daily FUEL reward
    const dailyRewardValue = dailyFuelReward.mul(fuelPrice).div(PRECISION);

    // Calculate APR: (daily reward value * 365 * 100) / TVL
    const apr = dailyRewardValue.mul(new BN(365)).mul(new BN(100)).div(tvl);

    return `+${parseBN(apr, 0, 0)}%`;
  }
  return "";
}

export function getAssetAprTooltip(apr: string) {
  if (!apr) return "";
  if (apr === "0%") return "No rewards available yet";
  return `Earn ${apr} APR in FUEL on your collateral`;
}
