import { useToast } from "@chakra-ui/react";
import { useAccount } from "@fuels/react";
import { Address, BN } from "fuels";
import { useEffect, useState } from "react";
import { TrovePosition } from "../shared/utils";
import { FptStakingContract } from "../types/fpt-staking-contract";
import { OracleContract } from "../types/oracle-contract";
import { TokenContract } from "../types/token-contract";
import { TroveManagerContract } from "../types/trove-manager-contract";
import { UsdfTokenContract } from "../types/usdf-token-contract";
import { useFluid } from "./FluidProvider";
import { IAssetContext } from "../types/fluid.types";
import { MockPythContract } from "../types/mock-pyth-contract";
import { BorrowOperationsContract } from "../types/borrow-operations-contract";
import { HintHelperContract } from "../types/hint-helper-contract";
import { PRECISION } from "../shared/format";
import { SortedTrovesContract } from "../types/sorted-troves-contract";
import { IdentityInput } from "../types/token-contract/TokenContract";

export async function calculateHints(
  assetId: string,
  troveManagerAddress: string,
  cr: BN,
  troveManager: TroveManagerContract,
  troveManagerImplementation: TroveManagerContract,
  sortedTrovesImplementation: SortedTrovesContract,
  sortedTrovesContract: SortedTrovesContract,
  hintHelper: HintHelperContract,
  returnNullHints: boolean = false
): Promise<{ nextHintId: IdentityInput; prevHintId: IdentityInput }> {
  const nullAddress = {
    bits: "0x0000000000000000000000000000000000000000000000000000000000000000",
  };
  const nullIdentity = {
    Address: nullAddress,
  };

  let nextHintId: IdentityInput = nullIdentity;
  let prevHintId: IdentityInput = nullIdentity;

  if (returnNullHints) {
    return { nextHintId, prevHintId };
  }

  try {
    const hintResult = await hintHelper.functions
      .get_approx_hint(
        { bits: assetId },
        { bits: troveManagerAddress },
        cr,
        15,
        Date.now()
      )
      .addContracts([
        troveManager,
        troveManagerImplementation,
        sortedTrovesImplementation,
        sortedTrovesContract,
      ])
      .get();

    const [hint_identity] = hintResult.value;

    const hint_nominal_cr = await troveManager.functions
      .get_nominal_icr(hint_identity)
      .addContracts([troveManagerImplementation])
      .get();

    if (hint_nominal_cr.value.lt(cr)) {
      nextHintId = hint_identity;
    } else {
      prevHintId = hint_identity;
    }
  } catch (e) {
    console.log("Failed to get hints, falling back to null identities", e);
    // Continue with null identities
  }

  return { nextHintId, prevHintId };
}

export const useBorrowOperations = () => {
  const toast = useToast();
  const { account } = useAccount();
  const {
    reloadData,
    sortedTrovesContract,
    activePoolContract,
    contracts,
    wallet,
  } = useFluid();
  const [isLoading, setIsLoading] = useState(false);

  const [borrowOperationsContract, setBorrowOperationsContract] =
    useState<BorrowOperationsContract | null>(null);

  useEffect(() => {
    if (contracts) {
      setBorrowOperationsContract(
        new BorrowOperationsContract(contracts.BorrowOperations, wallet)
      );
    }
  }, [wallet, contracts]);

  async function openTrove(collateralInput: BN, debtInput: BN, index: number) {
    if (
      borrowOperationsContract &&
      contracts.assets[index].troveManager &&
      sortedTrovesContract &&
      account &&
      activePoolContract &&
      collateralInput &&
      debtInput
    ) {
      setIsLoading(true);

      let assetId = contracts.assets[index].assetId;

      let assetContractAddress = new Address(
        contracts.assets[index].asset_contract
      ).toB256();
      let assetContractInstance = new TokenContract(
        assetContractAddress,
        wallet
      );

      let oracleAddress = new Address(contracts.assets[index].oracle).toB256();
      let oracle = new OracleContract(oracleAddress, wallet);

      let pythAddress = new Address(
        contracts.assets[index].pythContract
      ).toB256();
      let pyth = new MockPythContract(pythAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        contracts.assets[index].troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      let troveManagerImplementation = new TroveManagerContract(
        contracts.assets[index].troveManagerImplementation,
        wallet
      );

      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );

      let fptStakingAddress = new Address(contracts.fptStaking).toB256();
      let fptStaking = new FptStakingContract(fptStakingAddress, wallet);
      let hintHelperAddress = new Address(contracts.hintHelper).toB256();
      let hintHelper = new HintHelperContract(hintHelperAddress, wallet);

      // Calculate nominal CR (Collateral Ratio)
      const cr = collateralInput.mul(PRECISION).div(debtInput);

      const { nextHintId, prevHintId } = await calculateHints(
        assetId,
        troveManagerAddress,
        cr,
        troveManager,
        troveManagerImplementation,
        sortedTrovesImplementation,
        sortedTrovesContract,
        hintHelper,
        false
      );

      try {
        // Proceed with open_trove regardless of hint calculation success
        let result = await borrowOperationsContract.functions
          .open_trove(debtInput, prevHintId, nextHintId)
          .callParams({
            forward: {
              amount: collateralInput,
              assetId: assetId,
            },
          })
          .addContracts([
            oracle,
            activePoolContract,
            assetContractInstance,
            usdf,
            pyth,
            sortedTrovesContract,
            troveManager,
            fptStaking,
            borrowOperationsContract,
          ])
          .txParams({ tip: 1, gasLimit: 7000000, variableOutputs: 10 })
          .call();

        toast({
          title: "USDF Borrowed",
          description: "You have successfully borrowed USDF!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        setTimeout(() => {
          reloadData();
          setIsLoading(false);
        }, 1000);
      } catch (e: any) {
        console.log("error", e);
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  async function closeTrove(
    assetContext: IAssetContext,
    trove: TrovePosition,
    index: number
  ) {
    if (
      borrowOperationsContract &&
      account &&
      sortedTrovesContract &&
      activePoolContract
    ) {
      setIsLoading(true);
      let assetAddress = assetContext.contractIds.assetId;

      let assetContract = new TokenContract(
        assetContext.contractIds.asset_contract,
        wallet
      );

      let oracleAddress = new Address(assetContext.contractIds.oracle).toB256();
      const oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        assetContext.contractIds.troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      try {
        borrowOperationsContract.functions
          .close_trove({
            bits: assetAddress,
          })
          .addContracts([
            oracle,
            assetContract,
            usdf,
            sortedTrovesContract,
            troveManager,
            activePoolContract,
          ])
          .callParams({
            forward: {
              amount: trove.debt,
              assetId: contracts.UsdfAssetId,
            },
          })
          .txParams({ tip: 1, gasLimit: 4500000, variableOutputs: 10 })
          .call()
          .then(() => {
            toast({
              title: "Transaction Successful",
              description: "You have successfully closed your trove.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              reloadData();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  async function topUpTrove(
    assetContext: IAssetContext,
    collateralInput: BN,
    closeModal: () => void,
    index: number
  ) {
    if (
      borrowOperationsContract &&
      account &&
      sortedTrovesContract &&
      activePoolContract
    ) {
      setIsLoading(true);

      let assetContractAddress = new Address(
        assetContext.contractIds.asset_contract
      ).toB256();
      let assetId = assetContext.contractIds.assetId;

      let assetContract = new TokenContract(assetContractAddress, wallet);

      let oracleAddress = new Address(assetContext.contractIds.oracle).toB256();
      const oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        assetContext.contractIds.troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      // Add new contract instances for hint calculation
      let troveManagerImplementation = new TroveManagerContract(
        assetContext.contractIds.troveManagerImplementation,
        wallet
      );
      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );
      let hintHelper = new HintHelperContract(contracts.hintHelper, wallet);

      try {
        // Get current trove state to calculate new CR
        const troveState = assetContext.trove;

        // Calculate new CR after adding collateral
        const newColl = troveState.coll.add(collateralInput);
        const cr = newColl.mul(PRECISION).div(troveState.debt);

        // Calculate hints for the new position
        const { nextHintId, prevHintId } = await calculateHints(
          assetId,
          troveManagerAddress,
          cr,
          troveManager,
          troveManagerImplementation,
          sortedTrovesImplementation,
          sortedTrovesContract,
          hintHelper,
          false
        );

        borrowOperationsContract.functions
          .add_coll(prevHintId, nextHintId) // Updated to use hints
          .callParams({
            forward: {
              amount: collateralInput,
              assetId: assetId,
            },
          })
          .addContracts([
            oracle,
            assetContract,
            usdf,
            sortedTrovesContract,
            troveManager,
            activePoolContract,
          ])
          .txParams({ tip: 1, gasLimit: 15000000, variableOutputs: 10 })
          .call()
          .then(async (res) => {
            toast({
              title: "Transaction Successful",
              description: "You have successfully topped up your trove.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              reloadData(index);
              closeModal();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  async function withdrawCollFromTrove(
    assetContext: IAssetContext,
    collateralInput: BN,
    closeModal: () => void,
    index: number
  ) {
    if (
      borrowOperationsContract &&
      account &&
      sortedTrovesContract &&
      activePoolContract
    ) {
      setIsLoading(true);

      let assetAddress = new Address(
        assetContext.contractIds.asset_contract
      ).toB256();
      let assetContract = new TokenContract(assetAddress, wallet);
      let assetId = assetContext.contractIds.assetId;

      let oracleAddress = new Address(assetContext.contractIds.oracle).toB256();
      const oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        assetContext.contractIds.troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      // Add new contract instances for hint calculation
      let troveManagerImplementation = new TroveManagerContract(
        assetContext.contractIds.troveManagerImplementation,
        wallet
      );
      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );
      let hintHelper = new HintHelperContract(contracts.hintHelper, wallet);

      try {
        // Get current trove state to calculate new CR
        const troveState = assetContext.trove;

        // Calculate new CR after withdrawing collateral
        const newColl = troveState.coll.sub(collateralInput);
        const cr = newColl.mul(PRECISION).div(troveState.debt);

        // Calculate hints for the new position
        const { nextHintId, prevHintId } = await calculateHints(
          assetId,
          troveManagerAddress,
          cr,
          troveManager,
          troveManagerImplementation,
          sortedTrovesImplementation,
          sortedTrovesContract,
          hintHelper,
          false
        );

        borrowOperationsContract.functions
          .withdraw_coll(collateralInput, prevHintId, nextHintId, {
            // Updated to use hints
            bits: assetId,
          })
          .addContracts([
            oracle,
            assetContract,
            usdf,
            sortedTrovesContract,
            troveManager,
            activePoolContract,
          ])
          .txParams({ tip: 1, gasLimit: 15000000, variableOutputs: 4 })
          .call()
          .then(async (res) => {
            toast({
              title: "Transaction Successful",
              description:
                "You have successfully withdrawn collateral from your trove!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              reloadData(index);
              closeModal();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  async function borrowUsdfFromTrove(
    assetContext: IAssetContext,
    debtInput: BN,
    closeModal: () => void,
    index: number
  ) {
    if (
      borrowOperationsContract &&
      account &&
      sortedTrovesContract &&
      activePoolContract
    ) {
      setIsLoading(true);

      let assetContractAddress = new Address(
        assetContext.contractIds.asset_contract
      ).toB256();
      let assetContract = new TokenContract(assetContractAddress, wallet);
      let assetId = assetContext.contractIds.assetId;

      let oracleAddress = new Address(assetContext.contractIds.oracle).toB256();
      const oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        assetContext.contractIds.troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      // Add new contract instances for hint calculation
      let troveManagerImplementation = new TroveManagerContract(
        assetContext.contractIds.troveManagerImplementation,
        wallet
      );
      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );
      let hintHelper = new HintHelperContract(contracts.hintHelper, wallet);

      let fptStakingAddress = new Address(contracts.fptStaking).toB256();
      let fptStaking = new FptStakingContract(fptStakingAddress, wallet);

      try {
        // Get current trove state to calculate new CR
        const troveState = assetContext.trove;

        // Calculate new CR after borrowing
        const newDebt = troveState.debt.add(debtInput);
        const cr = troveState.coll.mul(PRECISION).div(newDebt);

        // Calculate hints for the new position
        let { nextHintId, prevHintId } = await calculateHints(
          assetId,
          troveManagerAddress,
          cr,
          troveManager,
          troveManagerImplementation,
          sortedTrovesImplementation,
          sortedTrovesContract,
          hintHelper,
          false
        );

        borrowOperationsContract.functions
          .withdraw_usdf(debtInput, prevHintId, nextHintId, {
            // Updated to use hints
            bits: assetId,
          })
          .addContracts([
            oracle,
            assetContract,
            usdf,
            sortedTrovesContract,
            troveManager,
            activePoolContract,
            fptStaking,
          ])
          .txParams({ tip: 1, gasLimit: 15000000, variableOutputs: 10 })
          .call()
          .then(async (result) => {
            toast({
              title: "Transaction Successful",
              description: "You have successfully borrowed USDF!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              reloadData();
              closeModal();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  async function repayUsdfToTrove(
    assetContext: IAssetContext,
    debtInput: BN,
    closeModal: () => void,
    index: number
  ) {
    if (
      borrowOperationsContract &&
      account &&
      sortedTrovesContract &&
      activePoolContract
    ) {
      setIsLoading(true);

      let assetAddress = new Address(
        assetContext.contractIds.asset_contract
      ).toB256();
      let assetContract = new TokenContract(assetAddress, wallet);
      let assetId = assetContext.contractIds.assetId;

      let oracleAddress = new Address(assetContext.contractIds.oracle).toB256();
      let oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);
      let usdfAssetId = contracts.UsdfAssetId;

      let troveManagerAddress = new Address(
        assetContext.contractIds.troveManager
      ).toB256();
      let troveManager = new TroveManagerContract(troveManagerAddress, wallet);

      // Add these new contract instances
      let troveManagerImplementation = new TroveManagerContract(
        assetContext.contractIds.troveManagerImplementation,
        wallet
      );
      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );
      let hintHelper = new HintHelperContract(contracts.hintHelper, wallet);

      try {
        // Get current trove state to calculate new CR
        const troveState = assetContext.trove;

        // Calculate new CR after repayment
        const newDebt = troveState.debt.sub(debtInput);
        const cr = troveState.coll.mul(PRECISION).div(newDebt);

        // Calculate hints for the new position
        let { nextHintId, prevHintId } = await calculateHints(
          assetId,
          troveManagerAddress,
          cr,
          troveManager,
          troveManagerImplementation,
          sortedTrovesImplementation,
          sortedTrovesContract,
          hintHelper,
          false
        );

        borrowOperationsContract.functions
          .repay_usdf(prevHintId, nextHintId, {
            // Updated to use hints
            bits: assetId,
          })
          .callParams({
            forward: {
              amount: debtInput,
              assetId: usdfAssetId,
            },
          })
          .addContracts([
            oracle,
            assetContract,
            usdf,
            sortedTrovesContract,
            troveManager,
            activePoolContract,
            borrowOperationsContract,
          ])
          .txParams({ tip: 1, gasLimit: 15000000, variableOutputs: 10 })
          .call()
          .then(async (result) => {
            toast({
              title: "Transaction Successful",
              description: "You have successfully repayed debt to your trove!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              reloadData();
              closeModal();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  return {
    borrowUsdfFromTrove,
    repayUsdfToTrove,
    openTrove,
    withdrawCollFromTrove,
    closeTrove,
    topUpTrove,
    isLoading,
  };
};
