import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  IconButton,
} from "@chakra-ui/react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { BN } from "@fuel-ts/math";
import { parseBN, PRECISION } from "../../shared/format";
import { MIN_COLLATERAL_RATIO_DECIMAL } from "../../shared/constants";

export const TroveStats = ({
  price,
  borrowedUsdf,
  usdfFee,
  collRatio,
  validUserInput,
  validCollateralInput,
}: {
  price: BN;
  borrowedUsdf: BN;
  usdfFee: BN;
  collRatio: number;
  validUserInput: boolean;
  validCollateralInput: boolean;
}) => {
  return (
    <TableContainer
      style={{ width: "100%", paddingTop: "10px" }}
      borderTop="1px solid rgba(255, 255, 255, 0.16)"
      color={"textSecondary"}
    >
      <Table border="0px" variant="unstyled" size={"sm"}>
        <Tbody style={{ display: "block" }}>
          <Tr style={{ display: "flex", justifyContent: "space-between" }}>
            <Td
              color={"textSecondary"}
              style={{ paddingBottom: "10px" }}
              fontFamily="IBM Plex Mono"
              fontWeight="bold"
            >
              USDF Borrow Fee (0.5%)
            </Td>
            <Td
              textAlign={"end"}
              color={"textSecondary"}
              fontFamily="IBM Plex Mono"
              fontWeight="bold"
            >
              {validUserInput ? parseBN(usdfFee, 9) : "———"}
            </Td>
          </Tr>
          <Tr style={{ display: "flex", justifyContent: "space-between" }}>
            <Td
              color={"textSecondary"}
              style={{ paddingBottom: "10px" }}
              fontFamily="IBM Plex Mono"
              fontWeight="bold"
            >
              Total Borrowed
            </Td>
            <Td textAlign={"end"} fontFamily="IBM Plex Mono" fontWeight="bold">
              {validUserInput
                ? parseBN(usdfFee.add(borrowedUsdf), 9) + " USDF"
                : "———"}
            </Td>
          </Tr>
          <Tr style={{ display: "flex", justifyContent: "space-between" }}>
            <Td
              color={"textSecondary"}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
              fontFamily="IBM Plex Mono"
              fontWeight="bold"
            >
              Liquidation Price
              <IconButton
                as="a"
                href="https://docs.hydrogenlabs.xyz/fluid-protocol-community/protocol-design/liquidation-and-stability-pool"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Docs"
                color={"textSecondary"}
                icon={<FaRegQuestionCircle />}
                size="sm"
                variant="ghost"
                mx={2}
              />
            </Td>
            <Td textAlign={"end"} fontFamily="IBM Plex Mono" fontWeight="bold">
              {validUserInput && collRatio !== 0
                ? "$" +
                  parseBN(
                    price.mul(MIN_COLLATERAL_RATIO_DECIMAL).div(collRatio),
                    9,
                    price
                      .mul(MIN_COLLATERAL_RATIO_DECIMAL)
                      .div(collRatio)
                      .lt(PRECISION)
                      ? 3
                      : 2
                  )
                : "———"}
            </Td>
          </Tr>
          <Tr
            color={
              collRatio < MIN_COLLATERAL_RATIO_DECIMAL && validCollateralInput
                ? "orange"
                : ""
            }
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Td
              color={"textSecondary"}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
              fontFamily="IBM Plex Mono"
              fontWeight="bold"
            >
              Collateral Ratio
              <IconButton
                as="a"
                href="https://docs.hydrogenlabs.xyz/fluid-protocol-community/protocol-design/borrowing"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Docs"
                color={"textSecondary"}
                icon={<FaRegQuestionCircle />}
                size="sm"
                variant="ghost"
                mx={2}
              />
            </Td>
            <Td textAlign={"end"} fontFamily="IBM Plex Mono" fontWeight="bold">
              {validCollateralInput ? collRatio + "%" : "———"}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
