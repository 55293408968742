import {
  Text,
  Card,
  CardBody,
  Image,
  VStack,
  Container,
  Button,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getAssetApr, getAssetAprTooltip } from "../../shared/utils";
import { IAssetContext } from "../../types/fluid.types";
import { BN } from "fuels";
export function AddTroveCard({
  assetContext,
  assets,
}: {
  assetContext: IAssetContext;
  assets: IAssetContext[];
}) {
  const navigate = useNavigate();
  const apr = getAssetApr(
    assetContext,
    assets.find((a) => a.symbol === "FUEL")?.price ?? new BN(0)
  );

  return (
    <Card
      w="100%"
      minW={["100%", "100%", "100%", "450px"]}
      minH={"300px"}
      h={"100%"}
      alignItems={"center"}
      borderRadius={15}
      backgroundColor={"bgDarkGrey"}
      border={"2px solid transparent"}
      transition={"all 0.3s ease"}
      fontWeight={"semibold"}
      fontFamily={"IBM Plex Mono"}
      fontStyle={"bold"}
      cursor={"pointer"}
      onClick={() => {
        // Route to '/' with state containing the asset symbol
        navigate("/", { state: { selectedAssetSymbol: assetContext.symbol } });
      }}
      _hover={{
        border: `2px solid ${assetContext.troveColor};`,
        boxShadow: `${assetContext.troveBoxColor} 0px 0px 50px;`,
        transition: "box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;",
      }}
    >
      <CardBody h={"100%"} w="100%" alignItems={"center"}>
        <VStack h={"100%"}>
          <HStack w="100%" spacing={2} justify="center">
            <Text>Create {assetContext.symbol} Trove</Text>
            {apr && (
              <Tooltip
                label={getAssetAprTooltip(apr)}
                hasArrow
                placement="top"
                bg="bgDarkGrey"
                color="white"
              >
                <Button
                  size="sm"
                  bg="rgba(0, 255, 0, 0.8)"
                  color="black"
                  _hover={{ bg: "rgba(0, 255, 0, 0.9)" }}
                >
                  {apr} APR
                </Button>
              </Tooltip>
            )}
          </HStack>

          <Container position={"relative"}>
            <svg
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
              width="250"
              height="250"
              viewBox="0 0 290 304"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="sc-vMGZd dZXcqd"
            >
              <g filter="url(#filter0_d_1082_3282_#00A3FF)">
                <circle
                  cx="145"
                  cy="150"
                  r="118"
                  stroke={assetContext.troveColor}
                  strokeWidth="4"
                  strokeDasharray="2 2"
                  shapeRendering="crispEdges"
                ></circle>
              </g>
              <defs>
                <filter
                  id="filter0_d_1082_3282_#00A3FF"
                  x="-6"
                  y="0"
                  width="304"
                  height="304"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feMorphology
                    radius="8"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1082_3282"
                  ></feMorphology>
                  <feOffset dx="1" dy="2"></feOffset>
                  <feGaussianBlur stdDeviation="12"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.243137 0 0 0 0 0.45098 0 0 0 0 0.768627 0 0 0 0.25 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1082_3282"
                  ></feBlend>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1082_3282"
                    result="shape"
                  ></feBlend>
                </filter>
              </defs>
            </svg>

            <Image
              style={{
                position: "absolute",

                left: "50%",
                transform: "translateX(-50%) translateY(95%)",
              }}
              borderRadius="full"
              src={assetContext.contractIds.imageUrl}
              alt="asset logo"
              height={[20]}
              justifySelf={"center"}
            />
          </Container>
        </VStack>
      </CardBody>
    </Card>
  );
}
