import {
  Modal,
  ModalOverlay,
  ModalContent,
  ButtonGroup,
  Button,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { BN } from "@fuel-ts/math";
import { useState } from "react";
import { useBorrowOperations } from "../../hooks/BorrowOperationsHook";
import { TrovePosition } from "../../hooks/FluidProvider";
import { parseBN, PRECISION } from "../../shared/format";
import { MIN_COLLATERAL_RATIO_DECIMAL } from "../../shared/constants";
import { IAssetContext } from "../../types/fluid.types";

export const AdjustCollateralModal = ({
  isOpen,
  onClose,
  trove,
  assetContext,
  index,
}: {
  isOpen: boolean;
  onClose: () => void;
  trove: TrovePosition;
  assetContext: IAssetContext;
  index: number;
}) => {
  const { topUpTrove, withdrawCollFromTrove, isLoading } =
    useBorrowOperations();
  const [tab, setTab] = useState<"topup" | "withdraw">("topup");
  const [collateralInput, setCollateralInput] = useState<BN>(new BN(0));
  const [collateralInputText, setCollateralInputText] = useState<string>("");

  const collateralRatio = !trove.debt.isZero()
    ? trove.coll.mul(100).mul(assetContext.price).div(trove.debt).div(PRECISION)
    : new BN(1);

  const proposedCollateral =
    tab === "topup"
      ? trove.coll.add(collateralInput)
      : trove.coll.sub(collateralInput);

  const proposedCollateralRatio = !trove.debt.isZero()
    ? proposedCollateral
        .mul(100)
        .mul(assetContext.price)
        .div(trove.debt)
        .div(PRECISION)
    : new BN(1);

  const liquidationPrice = !collateralRatio.isZero()
    ? assetContext.price.mul(MIN_COLLATERAL_RATIO_DECIMAL).div(collateralRatio)
    : new BN(0);
  const proposedLiquidationPrice = !proposedCollateralRatio.isZero()
    ? assetContext.price
        .mul(MIN_COLLATERAL_RATIO_DECIMAL)
        .div(proposedCollateralRatio)
    : new BN(0);

  function getMax() {
    return tab === "topup"
      ? assetContext.balance
      : trove.coll.sub(
          trove.coll.mul(MIN_COLLATERAL_RATIO_DECIMAL).div(collateralRatio)
        );
  }

  function isValidTopUp(): boolean {
    return (
      collateralInput.gt(new BN(0)) && collateralInput.lte(assetContext.balance)
    );
  }

  function isValidWithdraw(): boolean {
    return collateralInput.gt(new BN(0)) && collateralInput.lte(getMax());
  }

  function closeModal() {
    setCollateralInput(new BN(0));
    setCollateralInputText("");
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent
        backgroundColor={"rgba(17, 17, 17)"}
        m={4}
        fontFamily={"IBM Plex Mono"}
        fontWeight={"bold"}
      >
        <ButtonGroup w={"100%"}>
          <Button
            borderRadius={"4px 0 0 0"}
            backgroundColor={"bgMediumGrey"}
            w={"100%"}
            borderBottomColor={tab === "topup" ? "green.200" : "transparent"}
            borderRightColor={"gray.700"}
            borderBottomWidth={2}
            onClick={() => {
              setTab("topup");
              setCollateralInput(new BN(0));
              setCollateralInputText("");
            }}
          >
            Top Up
          </Button>
          <Button
            ml={"0 !important"}
            borderRadius={"0 4px 0 0"}
            borderBottomColor={tab === "withdraw" ? "green.200" : "transparent"}
            backgroundColor={"bgMediumGrey"}
            borderBottomWidth={2}
            onClick={() => {
              setTab("withdraw");
              setCollateralInput(new BN(0));
              setCollateralInputText("");
            }}
            w={"100%"}
          >
            Withdraw
          </Button>
        </ButtonGroup>
        <ModalBody>
          <VStack mt={2}>
            <VStack w="100%">
              {" "}
              <InputGroup size="md">
                <Input
                  backgroundColor={"bgDarkGrey"}
                  textAlign={"right"}
                  placeholder={`2.5 ${assetContext.symbol}`}
                  isInvalid={!isValidTopUp()}
                  fontSize={"xl"}
                  value={collateralInputText}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();
                    if (
                      inputValue.includes(".") &&
                      inputValue.split(".")[1]?.length > 9
                    ) {
                      return;
                    }
                    setCollateralInputText(e.target.value);

                    const isValidNumber = /^-?\d*(\.\d{0,9})?$/.test(
                      inputValue
                    );
                    if (isValidNumber) {
                      const parsedValue = parseFloat(inputValue);
                      setCollateralInput(new BN(parsedValue * PRECISION));
                    }
                  }}
                />
                {tab === "topup" && (
                  <InputLeftElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="xs"
                      px={4}
                      onClick={() => {
                        setCollateralInput(getMax());
                        setCollateralInputText(parseBN(getMax(), 9));
                      }}
                    >
                      MAX
                    </Button>
                  </InputLeftElement>
                )}
              </InputGroup>
              <Text
                my={"0 !important"}
                pr={3}
                textAlign={"end"}
                width={"100%"}
                fontSize={"sm"}
                color={"textSecondary"}
              >
                {tab === "topup"
                  ? "Balance: " +
                    parseBN(assetContext.balance, 9) +
                    ` ${assetContext.symbol}`
                  : "Trove Balance: " +
                    parseBN(trove.coll, 9) +
                    ` ${assetContext.symbol}`}
              </Text>
            </VStack>
            <Table size={"sm"}>
              <Tbody>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Collateral
                  </Td>
                  <Td
                    borderColor={"transparent"}
                    isNumeric
                    color={
                      tab === "topup" &&
                      !isValidTopUp() &&
                      !collateralInput.isZero()
                        ? "orange"
                        : ""
                    }
                  >
                    {parseBN(trove.coll, 9)}{" "}
                    {collateralInput.gtn(0)
                      ? " → " + parseBN(proposedCollateral, 9)
                      : ""}
                    &nbsp;{assetContext.symbol}
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Collateral Ratio
                  </Td>
                  <Td
                    borderColor={"transparent"}
                    isNumeric
                    color={
                      tab === "withdraw" &&
                      !isValidWithdraw() &&
                      !collateralInput.isZero()
                        ? "orange"
                        : ""
                    }
                  >
                    {parseBN(collateralRatio, 0)}
                    {collateralInput.gtn(0)
                      ? " → " + parseBN(proposedCollateralRatio, 0) + "%"
                      : "%"}
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Liquidation Price
                  </Td>
                  <Td borderColor={"transparent"} isNumeric>
                    {parseBN(
                      liquidationPrice,
                      9,
                      liquidationPrice.lt(PRECISION) ? 3 : 2
                    )}
                    {collateralInput.gtn(0)
                      ? " → " +
                        parseBN(
                          proposedLiquidationPrice,
                          9,
                          proposedLiquidationPrice.lt(PRECISION) ? 3 : 2
                        )
                      : ""}{" "}
                    USD
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Debt
                  </Td>
                  <Td borderColor={"transparent"} isNumeric>
                    {parseBN(trove.debt, 9)} USDF
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            width={"100%"}
            backgroundColor={"bgDarkGrey"}
            onClick={
              tab === "topup"
                ? () =>
                    topUpTrove(assetContext, collateralInput, closeModal, index)
                : () =>
                    withdrawCollFromTrove(
                      assetContext,
                      collateralInput,
                      closeModal,
                      index
                    )
            }
            isLoading={isLoading}
            isDisabled={tab === "topup" ? !isValidTopUp() : !isValidWithdraw()}
          >
            {tab === "topup" ? "Top Up" : "Withdraw"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
