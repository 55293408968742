import { Spacer, Text, VStack } from "@chakra-ui/react";
import { PageWrapper } from "../components/common/PageWrapper";
import { ConnectWalletButton } from "../components/common/ConnectWalletButton";
import { useWallet } from "../hooks/useWallet";
import { HistoryCard } from "../components/history/HistoryCard";

export const History = () => {
  const { account } = useWallet();

  return (
    <PageWrapper>
      {!account ? (
        <VStack alignSelf={"center"} spacing={8} minW={["", "", "", "500px"]}>
          <Text>
            Please connect your wallet to view your transaction history.
          </Text>
          <ConnectWalletButton />
        </VStack>
      ) : (
        <HistoryCard />
      )}
      <Spacer />
    </PageWrapper>
  );
};
