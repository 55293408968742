import { useToast } from "@chakra-ui/react";
import { useFluid } from "./FluidProvider";
import { useState } from "react";
import { ProtocolManagerContract } from "../types/protocol-manager-contract";
import { BN } from "fuels";
import { useAccount, useFuel } from "@fuels/react";
import { Address } from "fuels";
import { OracleContract } from "../types/oracle-contract";
import { UsdfTokenContract } from "../types/usdf-token-contract";
import { FptStakingContract } from "../types/fpt-staking-contract";
import { DefaultPoolContract } from "../types/default-pool-contract";
import { CollSurplusPoolContract } from "../types/coll-surplus-pool-contract";
import { CommunityIssuanceContract } from "../types/community-issuance-contract";
import { TroveManagerContract } from "../types/trove-manager-contract/TroveManagerContract";
import { SortedTrovesContract } from "../types/sorted-troves-contract";
import { ActivePoolContract } from "../types/active-pool-contract";
import { MockPythContract } from "../types/mock-pyth-contract";

export const useRedemptions = () => {
  const { wallet, contracts } = useFluid();
  const { account } = useAccount();
  const { fuel } = useFuel();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const nullAddress = {
    bits: "0x0000000000000000000000000000000000000000000000000000000000000000",
  };
  const nullIdentity = {
    Address: nullAddress,
  };

  const redeemCollateral = async (
    amount: BN,
    maxIterations: number,
    partialRedemptionHint: number
  ) => {
    if (!contracts || !account) return;

    setIsLoading(true);
    const wallet = await fuel.getWallet(account);

    try {
      // Initialize all oracles
      const oracles = contracts.assets.map((asset) => {
        const oracleAddress = new Address(asset.oracle).toB256();
        return new OracleContract(oracleAddress, wallet);
      });

      const oracleImplementations = contracts.assets.map((asset) => {
        return new OracleContract(asset.oracleImplementation, wallet);
      });

      let pythAddress = new Address(contracts.assets[0].pythContract).toB256();
      let pyth = new MockPythContract(pythAddress, wallet);

      const troveManagerContracts = contracts.assets.map((asset) => {
        const troveManagerAddress = new Address(asset.troveManager).toB256();
        return new TroveManagerContract(troveManagerAddress, wallet);
      });

      const troveManagerImplementations = contracts.assets.map((asset) => {
        return new TroveManagerContract(
          asset.troveManagerImplementation,
          wallet
        );
      });

      const usdfAddress = new Address(contracts.Usdf).toB256();
      const usdf = new UsdfTokenContract(usdfAddress, wallet);

      const usdfImplementation = new UsdfTokenContract(
        contracts.UsdfImplementation,
        wallet
      );

      const protocolManager = new ProtocolManagerContract(
        contracts.protocolManager,
        wallet
      );

      const protocolManagerImplementation = new ProtocolManagerContract(
        contracts.protocolManagerImplementation,
        wallet
      );

      const fptStakingAddress = new Address(contracts.fptStaking).toB256();
      const fptStaking = new FptStakingContract(fptStakingAddress, wallet);

      const fptStakingImplementation = new FptStakingContract(
        contracts.fptStakingImplementation,
        wallet
      );

      const activePoolAddress = new Address(contracts.activePool).toB256();
      const activePool = new ActivePoolContract(activePoolAddress, wallet);

      const defaultPoolAddress = new Address(contracts.defaultPool).toB256();
      const defaultPool = new DefaultPoolContract(defaultPoolAddress, wallet);

      const collSurplusPoolAddress = new Address(
        contracts.collateralSurplusPool
      ).toB256();
      const collSurplusPool = new CollSurplusPoolContract(
        collSurplusPoolAddress,
        wallet
      );

      const communityIssuanceAddress = new Address(
        contracts.communityIssuance
      ).toB256();
      const communityIssuance = new CommunityIssuanceContract(
        communityIssuanceAddress,
        wallet
      );

      const sortedTrovesAddress = new Address(contracts.sortedTroves).toB256();
      const sortedTroves = new SortedTrovesContract(
        sortedTrovesAddress,
        wallet
      );

      const sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );

      const tx = await protocolManager.functions
        .redeem_collateral(
          maxIterations,
          partialRedemptionHint,
          nullIdentity,
          nullIdentity
        )
        .callParams({
          forward: {
            amount: amount,
            assetId: contracts.UsdfAssetId,
          },
        })
        .addContracts([
          ...oracles,
          ...oracleImplementations,
          ...troveManagerContracts,
          ...troveManagerImplementations,
          sortedTroves,
          sortedTrovesImplementation,
          collSurplusPool,
          usdf,
          usdfImplementation,
          communityIssuance,
          fptStaking,
          fptStakingImplementation,
          activePool,
          defaultPool,
          pyth,
          protocolManager,
          protocolManagerImplementation,
        ])
        .txParams({ gasLimit: 22500000, variableOutputs: 8 })
        .call();

      console.log(tx);
      toast({
        title: "Redemption Successful",
        description: "Your collateral has been redeemed successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (e: any) {
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    redeemCollateral,
    isLoading,
  };
};
