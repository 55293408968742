import { Routes, Route } from "react-router-dom";

import { Home } from "./pages/Home";
import { MyTroves } from "./pages/MyTroves";
import { RiskyTroves } from "./pages/RiskyTroves";
import { Vesting } from "./pages/Vesting";
import { Staking } from "./pages/Staking";
import { AdminDashboard } from "./pages/AdminDashboard";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsOfService } from "./pages/TermsOfService";
import { Redeem } from "./pages/Redeem";
import { History } from "./pages/History";
const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/earn" element={<RiskyTroves />} />
    <Route path="/my-troves" element={<MyTroves />} />
    <Route path="/vesting" element={<Vesting />} />
    <Route path="/staking" element={<Staking />} />
    <Route path="/admin" element={<AdminDashboard />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    <Route path="/redeem" element={<Redeem />} />
    <Route path="/history" element={<History />} />
  </Routes>
);

export default AppRoutes;
