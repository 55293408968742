import { useToast } from "@chakra-ui/react";
import { useFluid } from "./FluidProvider";
import { useState, useEffect } from "react";
import { BorrowOperationsContract } from "../types/borrow-operations-contract";
import { ProtocolManagerContract } from "../types/protocol-manager-contract";

export const useAdminOperations = () => {
  const { assets, wallet, contracts } = useFluid();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [assetPauseStatuses, setAssetPauseStatuses] = useState<{
    [key: string]: boolean;
  }>({});
  const [borrowOperationsContract, setBorrowOperationsContract] =
    useState<BorrowOperationsContract | null>(null);
  const [pauser, setPauserAddress] = useState<string>("");
  const [protocolManagerContract, setProtocolManagerContract] =
    useState<ProtocolManagerContract | null>(null);
  const [protocolManagerOwnerState, setProtocolManagerOwnerState] = useState<{
    owner: string;
  } | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (contracts) {
      setBorrowOperationsContract(
        new BorrowOperationsContract(contracts.BorrowOperations, wallet)
      );
    }
  }, [wallet, contracts]);

  // Get contract pause status
  useEffect(() => {
    getContractStatus();
  }, [borrowOperationsContract]);

  // Get all asset pause statuses
  useEffect(() => {
    getAllAssetPauseStatuses();
  }, [borrowOperationsContract, assets]);

  // Get pauser address
  useEffect(() => {
    getPauserAddress();
  }, [borrowOperationsContract]);

  // Initialize protocol manager contract
  useEffect(() => {
    if (contracts) {
      console.log("Initializing protocol manager contract with:", contracts);
      setProtocolManagerContract(
        new ProtocolManagerContract(contracts.protocolManager, wallet)
      );
    }
  }, [wallet, contracts]);

  // Get protocol manager owner state
  useEffect(() => {
    getProtocolManagerOwner();
  }, [protocolManagerContract]);

  async function getContractStatus() {
    if (borrowOperationsContract) {
      try {
        const status = await borrowOperationsContract.functions
          .get_is_paused()
          .get();
        setIsPaused(status.value);
      } catch (error) {
        console.error("Error getting contract status:", error);
      }
    }
  }

  async function getPauserAddress() {
    if (borrowOperationsContract) {
      try {
        const response = await borrowOperationsContract.functions
          .get_pauser()
          .get();
        if ("Address" in response.value && response.value.Address) {
          setPauserAddress(response.value.Address.bits);
        }
      } catch (error) {
        console.error("Error getting pauser address:", error);
      }
    }
  }

  async function getAllAssetPauseStatuses() {
    if (borrowOperationsContract && assets.length > 0) {
      try {
        const statusPromises = assets.map((asset) =>
          borrowOperationsContract.functions
            .get_asset_pause_status({
              bits: asset.contractIds.assetId,
            })
            .get()
            .then((status) => ({
              assetId: asset.contractIds.assetId,
              status: status.value,
            }))
            .catch((error) => {
              console.error(
                `Error fetching pause status for ${asset.symbol}:`,
                error
              );
              return {
                assetId: asset.contractIds.assetId,
                status: false, // or whatever default value makes sense
              };
            })
        );

        const results = await Promise.all(statusPromises);

        const statuses = results.reduce(
          (acc, { assetId, status }) => ({
            ...acc,
            [assetId]: status,
          }),
          {}
        );

        setAssetPauseStatuses(statuses);
      } catch (error) {
        console.error("Error fetching asset pause statuses:", error);
      }
    }
  }

  async function setPauseStatus(isPaused: boolean) {
    if (borrowOperationsContract) {
      setIsLoading(true);
      try {
        await borrowOperationsContract.functions
          .set_pause_status(isPaused)
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Status Updated",
          description: `Contract has been ${
            isPaused ? "paused" : "unpaused"
          } successfully!`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        // Refresh the status
        await getContractStatus();
        setIsLoading(false);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  async function setPauser(address: string) {
    if (borrowOperationsContract) {
      setIsLoading(true);
      try {
        await borrowOperationsContract.functions
          .set_pauser({ Address: { bits: address } })
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Pauser Updated",
          description: "New pauser has been set successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        // Refresh the pauser address
        await getPauserAddress();
        setIsLoading(false);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  const setAssetPauseStatus = async (asset: string, isPaused: boolean) => {
    if (!borrowOperationsContract) return;

    setIsLoading(true);
    try {
      await borrowOperationsContract.functions
        .set_asset_pause_status(
          {
            bits: asset,
          },
          isPaused
        )
        .call();

      toast({
        title: "Asset Pause Status Updated",
        description: `Asset has been ${
          isPaused ? "paused" : "unpaused"
        } successfully!`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      // Refresh the asset statuses
      await getAllAssetPauseStatuses();
      setIsLoading(false);
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    }
  };

  async function getProtocolManagerOwner() {
    if (protocolManagerContract) {
      try {
        console.log("Fetching protocol manager owner...");
        const ownerState = await protocolManagerContract.functions
          .owner()
          .get();
        console.log("Received owner state:", ownerState);
        if (
          "Initialized" in ownerState.value &&
          ownerState.value.Initialized?.Address?.bits
        ) {
          setProtocolManagerOwnerState({
            owner: ownerState.value.Initialized.Address.bits,
          });
        }
      } catch (error) {
        console.error("Error getting protocol manager owner:", error);
      }
    } else {
      console.log("Protocol manager contract not initialized yet");
    }
  }

  async function transferProtocolManagerOwner(newOwnerAddress: string) {
    if (protocolManagerContract) {
      setIsLoading(true);
      try {
        await protocolManagerContract.functions
          .transfer_owner({ Address: { bits: newOwnerAddress } })
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Owner Updated",
          description: "Protocol Manager owner has been updated successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        await getProtocolManagerOwner();
        setIsLoading(false);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  return {
    setPauseStatus,
    setPauser,
    setAssetPauseStatus,
    isLoading,
    isPaused,
    pauser,
    assetPauseStatuses,
    transferProtocolManagerOwner,
    protocolManagerOwnerState,
  };
};
