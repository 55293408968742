import {
  VStack,
  Image,
  Link,
  Box,
  IconButton,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  FaGithub,
  FaDiscord,
  FaBook,
  FaExchangeAlt,
  FaHistory,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoCubeOutline } from "react-icons/io5";
import { GoGraph } from "react-icons/go";
import { VscGraph } from "react-icons/vsc";
import { BsDatabase } from "react-icons/bs";

import Logo from "../../images/LogoH.png";

interface NavLink {
  label: string;
  path: string;
  icon: JSX.Element;
  isExternal?: boolean;
}

const navLinks: NavLink[] = [
  { label: "Borrow", path: "/", icon: <FaExchangeAlt /> },
  { label: "My Troves", path: "/my-troves", icon: <IoCubeOutline /> },
  { label: "Earn", path: "/earn", icon: <GoGraph /> },
  { label: "Staking", path: "/staking", icon: <BsDatabase /> },
  { label: "History", path: "/history", icon: <FaHistory /> },
  {
    label: "Stats",
    path: "https://stats.fluidprotocol.xyz",
    icon: <VscGraph />,
    isExternal: true,
  },
  {
    label: "Swap",
    path: "https://mira.ly/swap/",
    icon: <ExternalLinkIcon mx="2px" />,
    isExternal: true,
  },
];

export const SideBar = () => {
  const currentPage = window.location.pathname;

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      height="100vh"
      width="250px"
      bg="rgba(0, 0, 0, 0.1)"
      p={2}
      display="flex"
      flexDirection="column"
      backdropFilter="blur(10px)"
    >
      <VStack spacing={4} align="start" width="100%">
        {/* Logo */}
        <Link href="/" width="100%">
          <Image w="200px" src={Logo} alt="Logo" />
        </Link>

        {/* Navigation Links */}
        <VStack spacing={4} align="start" width="100%" pl={6}>
          {navLinks.map((link) => (
            <Box
              key={link.path}
              as="button"
              width="100%"
              borderRadius="md"
              bg={currentPage === link.path ? "whiteAlpha.100" : "transparent"}
              _hover={{ bg: "whiteAlpha.50" }}
            >
              <Link
                color={
                  currentPage === link.path ? "textPrimary" : "textSecondary"
                }
                href={link.path}
                fontSize="md"
                fontFamily="IBM Plex Mono"
                fontWeight="bold"
                _hover={{ color: "textPrimary" }}
                display="flex"
                gap={2}
                alignItems="center"
                width="100%"
                p={2}
                pl={4}
                {...(link.isExternal && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}
              >
                {link.icon} {link.label}
              </Link>
            </Box>
          ))}
        </VStack>
      </VStack>

      <Spacer />

      {/* Social Links */}
      <HStack spacing={2} justify="center" width="100%" pb={4}>
        <IconButton
          as="a"
          href="https://twitter.com/Fluid_Protocol"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          icon={<FaXTwitter />}
          size="md"
          variant="ghost"
        />
        <IconButton
          as="a"
          href="https://github.com/Hydrogen-Labs/fluid-protocol"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub"
          icon={<FaGithub />}
          size="md"
          variant="ghost"
        />
        <IconButton
          as="a"
          href="https://discord.gg/PEQJZcPX8u"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Discord"
          icon={<FaDiscord />}
          size="md"
          variant="ghost"
        />
        <IconButton
          as="a"
          href="https://docs.hydrogenlabs.xyz/fluid-protocol-community"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Gitbook"
          icon={<FaBook />}
          size="md"
          variant="ghost"
        />
      </HStack>
    </Box>
  );
};
