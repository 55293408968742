import { useFuel } from "@fuels/react";
import { useWallet } from "./useWallet";
import React, {
  useState,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import { IFluidContext, Network, IAssetContext } from "../types/fluid.types";
import {
  getCachedValue,
  setCachedValue,
  PRICE_CACHE_TIMEOUT,
  FPT_PRICE_CACHE_TIMEOUT,
  TOTAL_USDF_CACHE_TIMEOUT,
} from "../utils/cache.utils";
import { fetchOpenTroves } from "../services/trove.service";
import { Address, AssetId, BN, Provider } from "fuels";
import { Contracts as TestnetContracts } from "../shared/contracts.testnet";
import {
  Contracts as MainnetContracts,
  ethContracts as MainnetEthContracts,
} from "../shared/contracts.mainnet";
import { TokenContract } from "../types/token-contract";
import { OracleContract } from "../types/oracle-contract";
import {
  IdentityInput,
  TroveManagerContract,
} from "../types/trove-manager-contract/TroveManagerContract";
import { StabilityPoolContract } from "../types/stability-pool-contract";
import { BorrowOperationsContract } from "../types/borrow-operations-contract";
import { SortedTrovesContract } from "../types/sorted-troves-contract";
import { ActivePoolContract } from "../types/active-pool-contract/ActivePoolContract";
import { MockPythContract } from "../types/mock-pyth-contract";
import { FptStakingContract } from "../types/fpt-staking-contract/FptStakingContract";
import { VestingContract } from "../types/vesting-contract/VestingContract";
import { UsdfTokenContract } from "../types/usdf-token-contract/UsdfTokenContract";
import { MAINNET_ENDPOINT, TESTNET_ENDPOINT } from "../shared/constants";
import { ReadonlyMiraAmm } from "mira-dex-ts";
import { PRECISION } from "../shared/format";
import { CommunityIssuanceContract } from "../types/community-issuance-contract/CommunityIssuanceContract";
import { CollSurplusPoolContract } from "../types/coll-surplus-pool-contract/CollSurplusPoolContract";

type Props = {
  children?: React.ReactNode;
};

export interface TrovePosition {
  debt: BN;
  coll: BN;
  debt_reward: BN;
  coll_reward: BN;
}

const defaultTrovePosition: TrovePosition = {
  debt: new BN(0),
  coll: new BN(0),
  debt_reward: new BN(0),
  coll_reward: new BN(0),
};

export function defaultAssetContext(): IAssetContext {
  return {
    troveColor: "",
    troveBoxColor: "",
    contractIds: MainnetEthContracts,
    collateralSurplus: new BN(0),
    balance: new BN(0),
    price: new BN(0),
    totalInActivePool: new BN(0),
    symbol: "",
    troveManagerContract: undefined,
    oracleContract: undefined,
    assetContract: undefined,
    oracleImplementation: undefined,
    trove: defaultTrovePosition,
    pythContract: undefined,
    redstoneContract: undefined,
  };
}

const defaultState: IFluidContext = {
  isTestnet: "unknown",
  setIsTestnet: () => {},
  contracts: MainnetContracts,
  wallet: undefined,
  address: null,
  usdfBalance: new BN(0),
  fptBalance: new BN(0),
  stabilityPoolContract: undefined,
  borrowOperationsContract: undefined,
  sortedTrovesContract: undefined,
  activePoolContract: undefined,
  fptStakingContract: undefined,
  vestingContract: undefined,
  assetsLoaded: false,
  assets: [],
  reloadData: () => {},
  totalUsdfMinted: new BN(0),
  usdfPrice: new BN(1_003_000_000),
  fptPrice: new BN(0),
  stabilityPoolAPR: new BN(0),
  remainingFptInStabilityPool: new BN(0),
  totalUSDFinPool: new BN(0),
};

const FluidContext = React.createContext<IFluidContext>(defaultState);

export function useFluid() {
  return useContext(FluidContext);
}

export const FluidProvider: React.FC<PropsWithChildren<Props>> = (
  children: Props
) => {
  const { fuel } = useFuel();

  const { account } = useWallet();
  const [isTestnet, setIsTestnet] = useState<Network>("unknown");
  const [assets, setAssets] = useState<IAssetContext[]>([]);
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false);
  const [stabilityPoolContract, setStabilityPoolContract] = useState<
    StabilityPoolContract | undefined
  >(undefined);
  const [usdfBalance, setUSDFBalance] = useState<BN>(new BN(0));
  const [fptBalance, setFptBalance] = useState<BN>(new BN(0));
  const [borrowOperationsContract, setBorrowOperations] = useState<
    BorrowOperationsContract | undefined
  >(undefined);
  const [sortedTrovesContract, setSortedTroves] = useState<
    SortedTrovesContract | undefined
  >(undefined);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [activePoolContract, setActivePool] = useState<
    ActivePoolContract | undefined
  >(undefined);
  const [wallet, setWallet] = useState<any>(undefined);
  const [fptStakingContract, setFptStakingContract] = useState<
    FptStakingContract | undefined
  >(undefined);
  const [vestingContract, setVestingContract] = useState<
    VestingContract | undefined
  >(undefined);
  const [totalUsdfMinted, setTotalUsdfMinted] = useState<BN>(new BN(0));
  const [usdfTokenContract, setUsdfTokenContract] = useState<
    UsdfTokenContract | undefined
  >(undefined);
  const [usdfPrice, setUsdfPrice] = useState<BN>(new BN(1_003_000_000));
  const [fptPrice, setFptPrice] = useState<BN>(new BN(0));
  const [stabilityPoolAPR, setStabilityPoolAPR] = useState<BN>(new BN(0));
  const [remainingFptInStabilityPool, setRemainingFptInStabilityPool] =
    useState<BN>(new BN(0));
  const [totalUSDFinPool, setTotalUSDFinPool] = useState<BN>(new BN(0));
  // TODO: Remove this once we have a real price feed
  const checkNetwork = async () => {
    if (fuel) {
      const network = await fuel.currentNetwork();

      setIsTestnet(network.chainId === 0 ? "testnet" : "mainnet");
    }
  };

  // Initial network check
  useEffect(() => {
    checkNetwork();
  }, [fuel]);

  // Create a variable to hold the correct contracts
  let Contracts = isTestnet === "testnet" ? TestnetContracts : MainnetContracts;

  useEffect(() => {
    if (!usdfTokenContract) return;

    const fetchTotalUsdfSupply = async () => {
      try {
        // Check cache first
        const cachedTotalUsdf = getCachedValue("total_usdf_minted", isTestnet);
        if (cachedTotalUsdf) {
          setTotalUsdfMinted(cachedTotalUsdf);
          return;
        }

        const totalSupply = await usdfTokenContract.functions
          .total_supply({
            bits: Contracts.UsdfAssetId,
          })
          .get();

        if (totalSupply.value) {
          // Cache the new value
          setCachedValue("total_usdf_minted", totalSupply.value, isTestnet);
          setTotalUsdfMinted(totalSupply.value);
        }
      } catch (error) {
        console.error("Error fetching total USDF supply:", error);
      }
    };

    fetchTotalUsdfSupply();
  }, [usdfTokenContract]);

  async function reloadData(assetIndex?: number, skipAssets: boolean = false) {
    await checkNetwork();
    if (!account || isTestnet === "unknown") return;
    Contracts = isTestnet === "testnet" ? TestnetContracts : MainnetContracts;

    fuel.getWallet(account).then(async (wallet) => {
      setWallet(wallet);

      // Always reload USDF and FPT balances
      wallet.getBalance(Contracts.UsdfAssetId).then((balance: BN) => {
        setUSDFBalance(balance);
      });

      wallet.getBalance(Contracts.fptAssetId).then((balance: BN) => {
        setFptBalance(balance);
      });

      if (!skipAssets) {
        // Fetch open troves data for all assets in one query
        const openTrovesMap = await fetchOpenTroves(
          account,
          isTestnet === "testnet"
        );
        if (assetIndex !== undefined) {
          // Reload only the specified asset
          const updatedAsset = await reloadAsset(
            wallet,
            assetIndex,
            openTrovesMap
          );
          setAssets((prevAssets) => {
            const newAssets = [...prevAssets];
            newAssets[assetIndex] = updatedAsset;
            return newAssets;
          });
        } else {
          // Reload all assets concurrently
          setAssetsLoaded(false);
          const assetPromises = Contracts.assets.map((_, idx) =>
            reloadAsset(wallet, idx, openTrovesMap)
          );
          const tempassets = await Promise.all(assetPromises);
          setAssets(tempassets);
          setAssetsLoaded(true);
        }
      }

      setIsDataLoaded(true);
    });
  }

  async function reloadAsset(
    wallet: any,
    idx: number,
    openTrovesMap: Map<string, boolean>
  ): Promise<IAssetContext> {
    if (!account || isTestnet === "unknown") return defaultAssetContext();
    let assetContracts = Contracts.assets[idx];

    let provider = await Provider.create(
      isTestnet === "testnet"
        ? "https://testnet.fuel.network/v1/graphql"
        : MAINNET_ENDPOINT,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          Accept: "*/*",
        },
      }
    );
    let assetContext = defaultAssetContext();
    assetContext.symbol = assetContracts.assetSymbol;
    assetContext.contractIds = assetContracts;
    assetContext.troveColor = assetContracts.color;
    assetContext.troveBoxColor = assetContracts.boxColor;

    let contractAddress = assetContracts.assetId;
    // Create a promise for balance retrieval
    const balancePromise = wallet.getBalance(contractAddress);

    let oracleContractAddress = new Address(assetContracts.oracle).toB256();
    const oracle = new OracleContract(oracleContractAddress, provider);
    assetContext.oracleContract = oracle;

    assetContext.oracleImplementation = assetContracts.oracleImplementation;

    let troveManagerAddress = new Address(assetContracts.troveManager).toB256();
    const troveManager = new TroveManagerContract(
      troveManagerAddress,
      provider
    );
    assetContext.troveManagerContract = troveManager;

    let assetAddress = assetContracts.assetId;
    const assetContract = new TokenContract(assetAddress, provider);
    assetContext.assetContract = assetContract;

    let pythContractAddress = new Address(assetContracts.pythContract).toB256();
    const pythContract = new MockPythContract(pythContractAddress, provider);
    assetContext.pythContract = pythContract;

    // Create a promise for price retrieval
    const cachedPrice = getCachedValue(
      `price_${assetContracts.assetId}`,
      isTestnet,
      PRICE_CACHE_TIMEOUT
    );

    const pricePromise = cachedPrice
      ? Promise.resolve({ value: cachedPrice })
      : oracle.functions
          .get_price()
          .addContracts([pythContract, oracle])
          .get()
          .then((result) => {
            // Cache the new price
            setCachedValue(
              `price_${assetContracts.assetId}`,
              result.value,
              isTestnet
            );
            return result;
          });

    // Check if this asset has an open trove
    const hasOpenTrove =
      isTestnet === "testnet"
        ? true
        : openTrovesMap.get(assetContracts.assetSymbol.toLowerCase()) || false;

    // Only create trove data promise if there's an open trove
    let troveDataPromise;
    let myB256Address = Address.fromString(account).toB256();
    let myAddress = { bits: myB256Address };
    const identityInput: IdentityInput = { Address: myAddress };
    if (hasOpenTrove) {
      troveDataPromise = assetContext.troveManagerContract?.functions
        .get_entire_debt_and_coll(identityInput)
        .get();
    } else {
      troveDataPromise = Promise.resolve(null);
    }

    // Add this new section to query the total amount in the Active Pool
    let activePoolAddress = new Address(Contracts.activePool).toB256();
    const activePool = new ActivePoolContract(activePoolAddress, provider);

    // Check cache first
    const cachedActivePool = getCachedValue(
      `activePool_${assetContracts.assetId}`,
      isTestnet
    );

    let collSurplusPoolAddress = new Address(
      Contracts.collateralSurplusPool
    ).toB256();
    const collSurplusPool = new CollSurplusPoolContract(
      collSurplusPoolAddress,
      provider
    );

    // Create the activePool query promise only if cache miss
    const totalInActivePoolPromise = cachedActivePool
      ? Promise.resolve({ value: cachedActivePool })
      : activePool.functions
          .get_asset({
            bits: assetContracts.assetId,
          })
          .get()
          .then((result) => {
            // Cache the new value
            setCachedValue(
              `activePool_${assetContracts.assetId}`,
              result.value,
              isTestnet
            );
            return result;
          });

    // Check cache first for collateral surplus
    const cachedCollateralSurplus = getCachedValue(
      `collateralSurplus_${assetContracts.assetId}_${myB256Address}`,
      isTestnet
    );

    const collateralSurplusPromise = cachedCollateralSurplus
      ? Promise.resolve({ value: cachedCollateralSurplus })
      : hasOpenTrove
      ? collSurplusPool.functions
          .get_collateral(identityInput, { bits: assetContracts.assetId })
          .get()
          .then((result) => {
            // Cache the new value
            setCachedValue(
              `collateralSurplus_${assetContracts.assetId}_${myB256Address}`,
              result.value,
              isTestnet
            );
            return result;
          })
      : Promise.resolve({ value: new BN(0) }); // Return 0 as a number, not BigInt

    // Await both promises here
    const [
      balance,
      priceResult,
      troveData,
      totalInActivePool,
      collateralSurplus,
    ] = await Promise.all([
      balancePromise.catch((e: any) => {
        console.log("error fetching balance", e);
        return new BN(0);
      }),
      pricePromise.catch((e: any) => {
        console.log("error fetching price", e);
        return { value: new BN(0) };
      }),
      troveDataPromise.catch((e: any) => {
        return null;
      }),
      totalInActivePoolPromise.catch((e: any) => {
        console.log("error fetching total in active pool", e);
        return { value: new BN(0) };
      }),
      collateralSurplusPromise.catch((e: any) => {
        console.log("error fetching collateral surplus", e);
        return { value: new BN(0) };
      }),
    ]);
    assetContext.balance = balance;
    assetContext.price = priceResult.value;
    assetContext.totalInActivePool = totalInActivePool.value;
    assetContext.collateralSurplus = collateralSurplus.value;
    // console.log("DEBUG troveData", troveData);
    // console.log("DEBUG priceResult", priceResult);
    // console.log("DEBUG balance", balance);
    // console.log("DEBUG totalInActivePool", totalInActivePool);

    if (troveData) {
      assetContext.trove = {
        debt: troveData.value[0],
        coll: troveData.value[1],
        debt_reward: troveData.value[2],
        coll_reward: troveData.value[3],
      };
    }

    return assetContext;
  }

  useEffect(() => {
    let retryTimer: NodeJS.Timeout;

    const attemptConnection = () => {
      if (!account) {
        setUSDFBalance(new BN(0));
        retryTimer = setTimeout(attemptConnection, 10000);
      } else {
        reloadData();
      }
    };

    attemptConnection();

    // Clean up function
    return () => {
      if (retryTimer) {
        clearTimeout(retryTimer);
      }
    };
  }, [account, isTestnet]);

  useEffect(() => {
    if (!wallet || isTestnet === "unknown") return;

    const setupContracts = async () => {
      let provider = await Provider.create(
        isTestnet === "testnet" ? TESTNET_ENDPOINT : MAINNET_ENDPOINT
      );
      let contractAddress = new Address(Contracts.BorrowOperations).toB256();
      const contract = new BorrowOperationsContract(contractAddress, provider);
      setBorrowOperations(contract);

      let stabilityPoolAddress = new Address(Contracts.stabilityPool).toB256();
      const stabilityPool = new StabilityPoolContract(
        stabilityPoolAddress,
        provider
      );
      setStabilityPoolContract(stabilityPool);

      let sortedTrovesAddress = new Address(Contracts.sortedTroves).toB256();
      const sortedTroves = new SortedTrovesContract(
        sortedTrovesAddress,
        provider
      );
      setSortedTroves(sortedTroves);

      let activePoolAddress = new Address(Contracts.activePool).toB256();
      const activePool = new ActivePoolContract(activePoolAddress, provider);
      setActivePool(activePool);

      let fptStakingAddress = new Address(Contracts.fptStaking).toB256();
      const fptStaking = new FptStakingContract(fptStakingAddress, provider);
      setFptStakingContract(fptStaking);

      let vestingAddress = new Address(Contracts.vesting).toB256();
      const vesting = new VestingContract(vestingAddress, wallet);
      setVestingContract(vesting);

      let usdfTokenAddress = new Address(Contracts.Usdf).toB256();
      const usdfToken = new UsdfTokenContract(usdfTokenAddress, provider);
      setUsdfTokenContract(usdfToken);
    };

    setupContracts();
  }, [wallet, isTestnet]);

  useEffect(() => {
    // This effect will run when the component mounts and when dependencies change
    const fetchUsdfPrice = async () => {
      try {
        // For now, we're using the hardcoded value
        // In the future, replace this with the actual query
        // TO
        const price = new BN(1_003_000_000);
        setUsdfPrice(price);
      } catch (error) {
        console.error("Error fetching USDF price:", error);
      }
    };

    fetchUsdfPrice();
  }, []);

  // Add this function to get Mira provider
  const getMiraProvider = () => {
    return Provider.create(
      isTestnet === "testnet" ? TESTNET_ENDPOINT : MAINNET_ENDPOINT,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          Accept: "*/*",
        },
      }
    ).then((provider) => new ReadonlyMiraAmm(provider));
  };

  // Add effect to calculate FPT price
  useEffect(() => {
    if (assets[1] && assets[1].price) {
      // Check cache first
      const cachedFptPrice = getCachedValue(
        "fpt_price",
        isTestnet,
        FPT_PRICE_CACHE_TIMEOUT
      );
      if (cachedFptPrice) {
        setFptPrice(cachedFptPrice);
        return;
      }

      getMiraProvider()
        .then((miraProvider) => {
          const fptAssetId = { bits: Contracts.fptAssetId };
          const ethAssetId = { bits: Contracts.assets[1].assetId };

          type PoolId = [AssetId, AssetId, boolean];
          const fptPricePools: PoolId[] = [[ethAssetId, fptAssetId, false]];

          return miraProvider.previewSwapExactInput(
            fptAssetId,
            new BN(PRECISION),
            fptPricePools
          );
        })
        .then((fptPriceInETH) => {
          const fptPriceInUSD = fptPriceInETH[1]
            .mul(assets[1].price)
            .div(new BN(PRECISION));

          // Cache the new FPT price
          setCachedValue("fpt_price", fptPriceInUSD, isTestnet);
          setFptPrice(fptPriceInUSD);
        })
        .catch((error) => {
          console.error("Error calculating FPT price:", error);
        });
    }
  }, [assets, Contracts]);

  useEffect(() => {
    const calculateStabilityPoolAPR = () => {
      Provider.create(
        isTestnet === "testnet" ? TESTNET_ENDPOINT : MAINNET_ENDPOINT
      ).then(async (provider) => {
        if (!wallet || !fptPrice || isTestnet === "unknown") return;

        const stabilityPool = new StabilityPoolContract(
          new Address(Contracts.stabilityPool).toB256(),
          provider
        );

        const communityIssuance = new CommunityIssuanceContract(
          new Address(Contracts.communityIssuance).toB256(),
          provider
        );

        try {
          // Get total USDF in pool
          // Check cache first
          const cachedTotalUsdf = getCachedValue(
            "total_usdf_in_pool",
            isTestnet,
            TOTAL_USDF_CACHE_TIMEOUT
          );

          if (cachedTotalUsdf) {
            setTotalUSDFinPool(cachedTotalUsdf);
          } else {
            const totalUSDFResult = await stabilityPool.functions
              .get_total_usdf_deposits()
              .txParams({ gasLimit: 2000000, variableOutputs: 3 })
              .get();

            // Cache the new value
            setCachedValue(
              "total_usdf_in_pool",
              totalUSDFResult.value,
              isTestnet
            );
            setTotalUSDFinPool(totalUSDFResult.value);
          }

          // Check cache first for remaining FPT
          const cachedRemainingFpt = getCachedValue(
            "remaining_fpt_stability_pool",
            isTestnet
          );
          let remainingFptInStabilityPool;

          if (cachedRemainingFpt) {
            remainingFptInStabilityPool = cachedRemainingFpt;
          } else {
            // Get remaining FPT if not cached
            const balance = await communityIssuance.getBalance(
              Contracts.fptAssetId
            );
            remainingFptInStabilityPool = balance.div(new BN(PRECISION));

            // Cache the new value
            setCachedValue(
              "remaining_fpt_stability_pool",
              remainingFptInStabilityPool,
              isTestnet
            );
          }

          setRemainingFptInStabilityPool(remainingFptInStabilityPool);

          if (totalUSDFinPool.gt(new BN(0))) {
            const fptIssuanceOneDay = calculateDailyFptIssuance(
              remainingFptInStabilityPool
            );
            const fptIssuanceOneDayInUSD = fptIssuanceOneDay.mul(fptPrice);
            const aprPercentage = fptIssuanceOneDayInUSD
              .mul(new BN(365))
              .mul(new BN(100))
              .div(totalUSDFinPool);

            setStabilityPoolAPR(aprPercentage);
          }
        } catch (error) {
          console.error("Error calculating stability pool APR:", error);
        }
      });
    };

    calculateStabilityPoolAPR();
  }, [wallet, fptPrice, isTestnet]);

  return (
    <FluidContext.Provider
      value={{
        isTestnet, // Add this to the context
        contracts: Contracts,
        stabilityPoolContract,
        address: account ? account : "",
        usdfBalance,
        borrowOperationsContract,
        activePoolContract,
        sortedTrovesContract,
        fptStakingContract,
        vestingContract,
        wallet,
        fptBalance,
        assets,
        assetsLoaded,
        reloadData,
        totalUsdfMinted,
        usdfPrice,
        fptPrice,
        setIsTestnet,
        stabilityPoolAPR,
        remainingFptInStabilityPool,
        totalUSDFinPool,
      }}
    >
      {children.children}
    </FluidContext.Provider>
  );
};

export function calculateDailyFptIssuance(remainingFpt: BN): BN {
  const dailyIssuanceFraction = new BN((1 - 0.5 ** (1 / 365)) * PRECISION);
  return remainingFpt
    .mul(dailyIssuanceFraction)
    .div(new BN(PRECISION))
    .div(new BN(2)); // Half is issued to stability pool without transition period
}
