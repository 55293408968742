import { useToast } from "@chakra-ui/react";
import { useAccount, useFuel } from "@fuels/react";
import { Address, BN } from "fuels";
import { useState } from "react";
import { CollSurplusPoolContract } from "../types/coll-surplus-pool-contract";
import { CommunityIssuanceContract } from "../types/community-issuance-contract";
import { DefaultPoolContract } from "../types/default-pool-contract";
import { FptStakingContract } from "../types/fpt-staking-contract";
import { OracleContract } from "../types/oracle-contract";
import { TokenContract } from "../types/token-contract";
import {
  IdentityInput,
  TroveManagerContract,
} from "../types/trove-manager-contract/TroveManagerContract";
import { UsdfTokenContract } from "../types/usdf-token-contract";
import { useFluid } from "./FluidProvider";
import { StabilityPoolContract } from "../types/stability-pool-contract";

export const useTroveManager = () => {
  const toast = useToast();
  const { account } = useAccount();
  const { fuel } = useFuel();
  const {
    activePoolContract,
    borrowOperationsContract,
    sortedTrovesContract,
    stabilityPoolContract,
    reloadData,
    contracts,
  } = useFluid();
  const [isLoading, setIsLoading] = useState(false);

  async function liquidateTrove(
    id: string,
    index: number,
    nextHintId: IdentityInput,
    isHintLTPostLiqRatio: boolean
  ) {
    if (
      borrowOperationsContract &&
      contracts.assets[index].troveManager &&
      sortedTrovesContract &&
      stabilityPoolContract &&
      activePoolContract &&
      account
    ) {
      const wallet = await fuel.getWallet(account);
      setIsLoading(true);

      let oracleAddress = new Address(contracts.assets[index].oracle).toB256();
      let oracle = new OracleContract(oracleAddress, wallet);

      let usdfAddress = new Address(contracts.Usdf).toB256();
      let usdf = new UsdfTokenContract(usdfAddress, wallet);

      let troveManagerAddress = new Address(
        contracts.assets[index].troveManager
      ).toB256();

      console.log("troveManagerAddress", troveManagerAddress);

      let troveManagerContract = new TroveManagerContract(
        troveManagerAddress,
        wallet
      );

      let fptStakingAddress = new Address(contracts.fptStaking).toB256();
      let fptStaking = new FptStakingContract(fptStakingAddress, wallet);

      let defaultPoolAddress = new Address(contracts.defaultPool).toB256();
      let defaultPool = new DefaultPoolContract(defaultPoolAddress, wallet);

      let collSurplusPoolAddress = new Address(
        contracts.collateralSurplusPool
      ).toB256();
      let collSurplusPool = new CollSurplusPoolContract(
        collSurplusPoolAddress,
        wallet
      );

      let communityIssuanceAddress = new Address(
        contracts.communityIssuance
      ).toB256();
      let communityIssuance = new CommunityIssuanceContract(
        communityIssuanceAddress,
        wallet
      );

      let stabilityPoolImplementation = new StabilityPoolContract(
        contracts.stabilityPoolImplementation,
        wallet
      );

      let myAddress = {
        bits: "0x0000000000000000000000000000000000000000000000000000000000000000",
      };

      const identityInput: IdentityInput = {
        Address: myAddress,
      };

      let troveIdentity: IdentityInput = {
        Address: {
          bits: id,
        },
      };

      try {
        // Handle case where nextHintId is undefined

        troveManagerContract.functions
          .liquidate(
            troveIdentity,
            isHintLTPostLiqRatio ? nextHintId : identityInput,
            isHintLTPostLiqRatio ? identityInput : nextHintId
          )
          .addContracts([
            stabilityPoolContract,
            stabilityPoolImplementation,
            sortedTrovesContract,
            oracle,
            activePoolContract,
            defaultPool,
            collSurplusPool,
            usdf,
            communityIssuance,
            fptStaking,
            borrowOperationsContract,
          ])
          .txParams({ tip: 1, gasLimit: 20000000, variableOutputs: 3 })
          .call()
          .then((res) => {
            toast({
              title: "USDF Borrowed",
              description: "You have successfully liquidated the trove",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            reloadData();
            setIsLoading(false);
          })
          .catch((e: any) => {
            console.log("error", e);
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  return {
    liquidateTrove,
    isLoading,
  };
};
