import axios from "axios";

export interface HistoryEvent {
  type: string;
  asset: string;
  collateral?: string;
  debt?: string;
  amount?: string;
  timestamp: number;
  txHash: string;
}

export async function fetchUserHistory(account: string, isTestnet: boolean) {
  if (isTestnet) {
    return [];
  }

  const normalizedAccount = account.toLowerCase();

  const query = `query {
    BorrowOperations_OpenTroveEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      collateral
      debt
      timestamp
      txHash
    }
    BorrowOperations_CloseTroveEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      collateral
      debt
      timestamp
      txHash
    }
    BorrowOperations_AdjustTroveEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      collateral
      debt
      timestamp
      txHash
    }
    StabilityPool_ProvideToStabilityPoolEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      amount
      timestamp
      txHash
    }
    StabilityPool_WithdrawFromStabilityPoolEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      amount
      timestamp
      txHash
    }
    TroveManager_TrovePartialLiquidationEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      remaining_debt
      remaining_collateral
      timestamp
      txHash
    }
    TroveManager_TroveFullLiquidationEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      debt
      collateral
      timestamp
      txHash
    }
    TroveManager_RedemptionEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      usdf_amount
      collateral_amount
      timestamp
      txHash
    }
    FPTStaking_StakeEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      amount
      timestamp
      txHash
    }
    FPTStaking_UnstakeEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      amount
      timestamp
      txHash
    }
  }`
    .replace(/\s+/g, " ")
    .trim();

  try {
    const response = await axios.post(
      "https://stats.fluidprotocol.xyz/v1/graphql",
      {
        query,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      }
    );

    if (!response.data?.data) return [];

    const events: HistoryEvent[] = [];
    const data = response.data.data;

    // Process Open Trove events
    data.BorrowOperations_OpenTroveEvent?.forEach((event: any) => {
      events.push({
        type: "Open Trove",
        asset: event.asset,
        collateral: event.collateral,
        debt: event.debt,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Close Trove events
    data.BorrowOperations_CloseTroveEvent?.forEach((event: any) => {
      events.push({
        type: "Close Trove",
        asset: event.asset,
        collateral: event.collateral,
        debt: event.debt,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Adjust Trove events
    data.BorrowOperations_AdjustTroveEvent?.forEach((event: any) => {
      events.push({
        type: "Adjust Trove",
        asset: event.asset,
        collateral: event.collateral,
        debt: event.debt,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Stability Pool Deposits
    data.StabilityPool_ProvideToStabilityPoolEvent?.forEach((event: any) => {
      events.push({
        type: "Deposit to Stability Pool",
        asset: "USDF",
        amount: event.amount,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Stability Pool Withdrawals
    data.StabilityPool_WithdrawFromStabilityPoolEvent?.forEach((event: any) => {
      events.push({
        type: "Withdraw from Stability Pool",
        asset: "USDF",
        amount: event.amount,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Partial Liquidations
    data.TroveManager_TrovePartialLiquidationEvent?.forEach((event: any) => {
      events.push({
        type: "Partial Liquidation",
        asset: event.asset,
        collateral: event.remaining_collateral,
        debt: event.remaining_debt,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Full Liquidations
    data.TroveManager_TroveFullLiquidationEvent?.forEach((event: any) => {
      events.push({
        type: "Full Liquidation",
        asset: event.asset,
        collateral: event.collateral,
        debt: event.debt,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Redemptions
    data.TroveManager_RedemptionEvent?.forEach((event: any) => {
      events.push({
        type: "Redemption",
        asset: event.asset,
        amount: event.usdf_amount,
        collateral: event.collateral_amount,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Stake events
    data.FPTStaking_StakeEvent?.forEach((event: any) => {
      events.push({
        type: "Stake FPT",
        asset: "FPT",
        amount: event.amount,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Process Unstake events
    data.FPTStaking_UnstakeEvent?.forEach((event: any) => {
      events.push({
        type: "Unstake FPT",
        asset: "FPT",
        amount: event.amount,
        timestamp: event.timestamp,
        txHash: event.txHash,
      });
    });

    // Sort all events by timestamp (newest first)
    return events.sort((a, b) => b.timestamp - a.timestamp);
  } catch (error) {
    console.error("Error fetching user history:", error);
    return [];
  }
}
