import {
  VStack,
  Grid,
  HStack,
  GridItem,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AdjustTroveCard } from "../components/my-troves/AdjustTroveCard";
import { useFluid } from "../hooks/FluidProvider";
import { PageWrapper } from "../components/common/PageWrapper";
import { AddTroveCard } from "../components/my-troves/AddTroveCard";
import { ConnectWalletButton } from "../components/common/ConnectWalletButton";
import { useWallet } from "../hooks/useWallet";

export const MyTroves = () => {
  const { assets } = useFluid();
  const { account } = useWallet();

  // Sort assets by debt amount (highest to lowest), but maintain index order for zero debt
  let assetsArray = [...assets].sort((a, b) => {
    if (a?.trove.debt.gt(0) && b?.trove.debt.gt(0)) {
      return b.trove.debt.gt(a.trove.debt) ? 1 : -1;
    }
    // If neither has debt, maintain original order
    if (!a?.trove.debt.gt(0) && !b?.trove.debt.gt(0)) {
      return 0;
    }
    // If only one has debt, put it first
    return a?.trove.debt.gt(0) ? -1 : 1;
  });

  return (
    <PageWrapper>
      <HStack
        justifyContent={"center"}
        display={["block", "flex"]}
        spacing={12}
        w="100%"
      >
        <VStack
          alignSelf={"center"}
          spacing={8}
          maxW={["100%", "100%", "100%", "1000px"]}
          w="100%"
        >
          <Grid
            w={"100%"}
            templateColumns={"repeat(2, 1fr)"}
            gap={6}
            justifyContent={"center"}
            maxW={["100%", "100%", "100%", "1200px"]}
          >
            {assetsArray.map((asset, ind) => {
              if (asset?.trove.debt.gt(0)) {
                return (
                  <GridItem key={"grid" + ind} colSpan={[2, 2, 2, 1]}>
                    <AdjustTroveCard
                      key={"adjust" + ind}
                      index={ind}
                      assetContext={asset}
                      assets={assetsArray}
                    />
                  </GridItem>
                );
              } else if (!asset.contractIds.disabled) {
                return (
                  <GridItem key={"grid" + ind} colSpan={[2, 2, 2, 1]}>
                    <AddTroveCard assetContext={asset} assets={assetsArray} />
                  </GridItem>
                );
              }
              return null;
            })}
          </Grid>
          {!account && (
            <>
              <Text>Please connect your wallet to view your troves.</Text>
              <ConnectWalletButton />
            </>
          )}
          {account && assetsArray.length === 0 && (
            <Stack direction="row" spacing={4} justifyContent="center">
              <Spinner size="xl" />
            </Stack>
          )}
        </VStack>
      </HStack>
    </PageWrapper>
  );
};
