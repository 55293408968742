import {
  Text,
  VStack,
  Card,
  CardBody,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Center,
  Spinner,
  TableContainer,
  useClipboard,
  useToast,
  Button,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CopyIcon } from "@chakra-ui/icons";
import { useFluid } from "../../hooks/FluidProvider";
import { fetchUserHistory, HistoryEvent } from "../../services/history.service";
import { parseBN } from "../../shared/format";
import { BN } from "fuels";

export const HistoryCard = () => {
  const MAX_EVENTS = 10;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [events, setEvents] = useState<HistoryEvent[]>([]);
  const { wallet, isTestnet, address } = useFluid();
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const toast = useToast();

  useEffect(() => {
    if (value && !hasCopied) {
      onCopy();
      toast({
        title: "Transaction hash copied",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }, [value]);

  useEffect(() => {
    if (wallet?.address) {
      loadHistory();
    }
  }, [wallet, currentPage]);

  const loadHistory = async () => {
    setIsLoading(true);
    const history = await fetchUserHistory(
      address || "",
      isTestnet === "testnet"
    );
    setEvents(history);
    setIsLoading(false);
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const paginatedEvents = events.slice(
    currentPage * MAX_EVENTS,
    (currentPage + 1) * MAX_EVENTS
  );
  const totalPages = Math.ceil(events.length / MAX_EVENTS);
  const canGoNext = currentPage < totalPages - 1;
  const canGoPrev = currentPage > 0;

  return (
    <Card variant={"darkCard"}>
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={2}>
          <Text
            display={"flex"}
            flexDir={"row"}
            variant={"cardTitle"}
            fontFamily="IBM Plex Mono"
            fontWeight="bold"
          >
            Transaction History
          </Text>

          {isTestnet === "testnet" ? (
            <Center py={8}>
              <Text
                fontFamily="IBM Plex Mono"
                fontWeight="bold"
                color="gray.500"
              >
                Transaction history is only available on mainnet
              </Text>
            </Center>
          ) : (
            <>
              <TableContainer
                backgroundColor={"bgLightGrey"}
                py={4}
                borderRadius={10}
                pb={2}
                w={"100%"}
                fontFamily="IBM Plex Mono"
                fontWeight="bold"
              >
                <Table w={"100%"} size="md" border={"transparent"}>
                  <Thead w={"100%"}>
                    <Tr w={"100%"}>
                      <Th
                        border={"transparent"}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        Date
                      </Th>
                      <Th
                        border={"transparent"}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        Type
                      </Th>
                      <Th
                        border={"transparent"}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        Asset
                      </Th>
                      <Th
                        border={"transparent"}
                        isNumeric
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        Amount
                      </Th>
                      <Th
                        border={"transparent"}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        Transaction
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody w={"100%"}>
                    {paginatedEvents.map((event) => (
                      <Tr
                        key={event.txHash}
                        fontSize={"sm"}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      >
                        <Td border={"transparent"}>
                          {formatDate(event.timestamp)}
                        </Td>
                        <Td border={"transparent"}>{event.type}</Td>
                        <Td border={"transparent"}>{event.asset}</Td>
                        <Td border={"transparent"} isNumeric>
                          {event.amount
                            ? parseBN(new BN(event.amount), 9)
                            : event.collateral
                            ? `${parseBN(
                                new BN(event.collateral),
                                9
                              )} (Coll) / ${parseBN(
                                new BN(event.debt || "0"),
                                9
                              )} (Debt)`
                            : "-"}
                        </Td>
                        <Td border={"transparent"}>
                          <Text
                            as="a"
                            href={`https://app.fuel.network/tx/${event.txHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            _hover={{ textDecoration: "underline" }}
                          >
                            {event.txHash.substring(0, 8)}...
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  {!isLoading && paginatedEvents.length === 0 && (
                    <Tbody w={"100%"}>
                      <Tr>
                        <Td colSpan={5}>
                          <Center py={8}>
                            <VStack spacing={2}>
                              <Text
                                fontFamily="IBM Plex Mono"
                                fontWeight="bold"
                                fontSize="lg"
                              >
                                No transactions yet
                              </Text>
                              <Text
                                fontFamily="IBM Plex Mono"
                                color="gray.500"
                                fontSize="sm"
                              >
                                Your transaction history will appear here
                              </Text>
                            </VStack>
                          </Center>
                        </Td>
                      </Tr>
                    </Tbody>
                  )}
                  {isLoading && (
                    <Tbody w={"100%"}>
                      <Tr>
                        <Td colSpan={5}>
                          <Center>
                            <Spinner />
                          </Center>
                        </Td>
                      </Tr>
                    </Tbody>
                  )}
                </Table>
              </TableContainer>

              <HStack w="100%" justifyContent="flex-end" pt={2}>
                <Button
                  size="sm"
                  onClick={() => setCurrentPage((p) => p - 1)}
                  isDisabled={!canGoPrev}
                  fontFamily="IBM Plex Mono"
                >
                  Previous
                </Button>
                <Text
                  fontFamily="IBM Plex Mono"
                  fontSize="md"
                  fontWeight="semibold"
                >
                  Page {currentPage + 1} of {Math.max(1, totalPages)}
                </Text>
                <Button
                  size="sm"
                  onClick={() => setCurrentPage((p) => p + 1)}
                  isDisabled={!canGoNext}
                  fontFamily="IBM Plex Mono"
                >
                  Next
                </Button>
              </HStack>
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
