/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.1
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum, Vec } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;
export enum StatusInput { NonExistent = 'NonExistent', Active = 'Active', ClosedByOwner = 'ClosedByOwner', ClosedByLiquidation = 'ClosedByLiquidation', ClosedByRedemption = 'ClosedByRedemption' };
export enum StatusOutput { NonExistent = 'NonExistent', Active = 'Active', ClosedByOwner = 'ClosedByOwner', ClosedByLiquidation = 'ClosedByLiquidation', ClosedByRedemption = 'ClosedByRedemption' };

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type RedemptionEventInput = { borrower: IdentityInput, usdf_amount: BigNumberish, collateral_amount: BigNumberish, collateral_price: BigNumberish };
export type RedemptionEventOutput = { borrower: IdentityOutput, usdf_amount: BN, collateral_amount: BN, collateral_price: BN };
export type RewardSnapshotInput = { asset: BigNumberish, usdf_debt: BigNumberish };
export type RewardSnapshotOutput = { asset: BN, usdf_debt: BN };
export type SingleRedemptionValuesInput = { usdf_lot: BigNumberish, asset_lot: BigNumberish, cancelled_partial: boolean };
export type SingleRedemptionValuesOutput = { usdf_lot: BN, asset_lot: BN, cancelled_partial: boolean };
export type TroveFullLiquidationEventInput = { borrower: IdentityInput, debt: BigNumberish, collateral: BigNumberish };
export type TroveFullLiquidationEventOutput = { borrower: IdentityOutput, debt: BN, collateral: BN };
export type TrovePartialLiquidationEventInput = { borrower: IdentityInput, remaining_debt: BigNumberish, remaining_collateral: BigNumberish };
export type TrovePartialLiquidationEventOutput = { borrower: IdentityOutput, remaining_debt: BN, remaining_collateral: BN };

export type TroveManagerContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "(u64, u64, u64, u64)",
      "concreteTypeId": "8e01ff69affed33f4459c1cd8303bcc6d694800f53e508dd1e088a51017b6e11",
      "metadataTypeId": 0
    },
    {
      "type": "bool",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
    },
    {
      "type": "enum libraries::trove_manager_interface::data_structures::Status",
      "concreteTypeId": "d77dc0b3aed9ac72bca050e16bf6108f63bff9a5242c1f2dae27b96e57feb75e",
      "metadataTypeId": 2
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 3
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct events::RedemptionEvent",
      "concreteTypeId": "bc3c9b6e36a9659a17b5a21d100190d6ec41ebe11bdec4e71f5490de581b0fc3",
      "metadataTypeId": 6
    },
    {
      "type": "struct events::TroveFullLiquidationEvent",
      "concreteTypeId": "3626aa2dcd6faf45cc732c134dd9a4e32bbd18779a049ae0caedba97d6d7d7c5",
      "metadataTypeId": 7
    },
    {
      "type": "struct events::TrovePartialLiquidationEvent",
      "concreteTypeId": "13a5f366e56e2599f5a3204b4da6705fda2b555fb3ca88e8004da9f0d25c502c",
      "metadataTypeId": 8
    },
    {
      "type": "struct libraries::trove_manager_interface::data_structures::RewardSnapshot",
      "concreteTypeId": "efc9935f720f8852029b032715940423f17d57e1836fc68488044a6e409872a5",
      "metadataTypeId": 9
    },
    {
      "type": "struct libraries::trove_manager_interface::data_structures::SingleRedemptionValues",
      "concreteTypeId": "3cb5d5df3bbbe962954821bded9131f9cd6cd55c5ef86f88f651c883af387635",
      "metadataTypeId": 10
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 12
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 13
    },
    {
      "type": "struct std::vec::Vec<enum std::identity::Identity>",
      "concreteTypeId": "2f79033d0d3729398611309f48578b56cf5162ba85e50f4d8fb79c9d9d1abc7b",
      "metadataTypeId": 15,
      "typeArguments": [
        "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
      ]
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "(_, _, _, _)",
      "metadataTypeId": 0,
      "components": [
        {
          "name": "__tuple_element",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "__tuple_element",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "__tuple_element",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "__tuple_element",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "b256",
      "metadataTypeId": 1
    },
    {
      "type": "enum libraries::trove_manager_interface::data_structures::Status",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "NonExistent",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "Active",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "ClosedByOwner",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "ClosedByLiquidation",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "ClosedByRedemption",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "Address",
          "typeId": 11
        },
        {
          "name": "ContractId",
          "typeId": 13
        }
      ]
    },
    {
      "type": "generic T",
      "metadataTypeId": 4
    },
    {
      "type": "raw untyped ptr",
      "metadataTypeId": 5
    },
    {
      "type": "struct events::RedemptionEvent",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "borrower",
          "typeId": 3
        },
        {
          "name": "usdf_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "collateral_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "collateral_price",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::TroveFullLiquidationEvent",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "borrower",
          "typeId": 3
        },
        {
          "name": "debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::TrovePartialLiquidationEvent",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "borrower",
          "typeId": 3
        },
        {
          "name": "remaining_debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "remaining_collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct libraries::trove_manager_interface::data_structures::RewardSnapshot",
      "metadataTypeId": 9,
      "components": [
        {
          "name": "asset",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "usdf_debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct libraries::trove_manager_interface::data_structures::SingleRedemptionValues",
      "metadataTypeId": 10,
      "components": [
        {
          "name": "usdf_lot",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "asset_lot",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "cancelled_partial",
          "typeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 11,
      "components": [
        {
          "name": "bits",
          "typeId": 1
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 12,
      "components": [
        {
          "name": "bits",
          "typeId": 1
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 13,
      "components": [
        {
          "name": "bits",
          "typeId": 1
        }
      ]
    },
    {
      "type": "struct std::vec::RawVec",
      "metadataTypeId": 14,
      "components": [
        {
          "name": "ptr",
          "typeId": 5
        },
        {
          "name": "cap",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        4
      ]
    },
    {
      "type": "struct std::vec::Vec",
      "metadataTypeId": 15,
      "components": [
        {
          "name": "buf",
          "typeId": 14,
          "typeArguments": [
            {
              "name": "",
              "typeId": 4
            }
          ]
        },
        {
          "name": "len",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        4
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "add_trove_owner_to_array",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "apply_pending_rewards",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "borrowers",
          "concreteTypeId": "2f79033d0d3729398611309f48578b56cf5162ba85e50f4d8fb79c9d9d1abc7b"
        },
        {
          "name": "upper_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "batch_liquidate_troves",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "close_trove",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "value",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "decrease_trove_coll",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "value",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "decrease_trove_debt",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "price",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "get_current_icr",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_entire_debt_and_coll",
      "output": "8e01ff69affed33f4459c1cd8303bcc6d694800f53e508dd1e088a51017b6e11",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_entire_system_debt",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_nominal_icr",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_pending_asset_rewards",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "address",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_pending_usdf_rewards",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_trove_coll",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_trove_debt",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "index",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "get_trove_owner_by_index",
      "output": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_trove_owners_count",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_trove_rewards_snapshot",
      "output": "efc9935f720f8852029b032715940423f17d57e1836fc68488044a6e409872a5",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_trove_stake",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_trove_status",
      "output": "d77dc0b3aed9ac72bca050e16bf6108f63bff9a5242c1f2dae27b96e57feb75e",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "has_pending_rewards",
      "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "coll",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "increase_trove_coll",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "debt",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "increase_trove_debt",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "borrow_operations",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "sorted_troves",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "oracle",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "stability_pool",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "default_pool",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "active_pool",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "coll_surplus_pool",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "usdf_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "protocol_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "upper_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "liquidate",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "borrower",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "max_usdf_amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "price",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "partial_redemption_hint",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "upper_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_partial_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "redeem_collateral_from_trove",
      "output": "3cb5d5df3bbbe962954821bded9131f9cd6cd55c5ef86f88f651c883af387635",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "remove_stake",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "status",
          "concreteTypeId": "d77dc0b3aed9ac72bca050e16bf6108f63bff9a5242c1f2dae27b96e57feb75e"
        }
      ],
      "name": "set_trove_status",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "update_stake_and_total_stakes",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "update_trove_reward_snapshots",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "logId": "1415805281139303833",
      "concreteTypeId": "13a5f366e56e2599f5a3204b4da6705fda2b555fb3ca88e8004da9f0d25c502c"
    },
    {
      "logId": "3901993240860012357",
      "concreteTypeId": "3626aa2dcd6faf45cc732c134dd9a4e32bbd18779a049ae0caedba97d6d7d7c5"
    },
    {
      "logId": "13563887075398870426",
      "concreteTypeId": "bc3c9b6e36a9659a17b5a21d100190d6ec41ebe11bdec4e71f5490de581b0fc3"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 94376
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "0aeae398466a6d8fa37fea0d61049be2b866915d3f385d81e4199cb6c9523d8a",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "0b110d817305a4bcf6b16c177121895a6badeaa0d6db9f8cbf3edcb1a476fd90",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "0efba22c47609fe930607b051253eb2807de1207e5d2990b86497f6f9daf0424",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "131556eb463c6ac54a5c1c08692d7396f3d8dcd4212b268431a5bf3faefc39c6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2236b8c9f4a76aec120c3785aded7237952d02a33085be4e6f9c2be26a730c8c",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2aa95707a42f70b4725e4552a7188e45ac9ed6175dafb119b55b2abc7e96247d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2b1353bd3957665c0d731dcc29c245f50c99e1256a252a98fbcebc1c642348f4",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "3739a5fed15b66f9b9a1552739b50d3e646e441277daa7f8ae799efc5946c4d3",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "3829c209acdc9f2ef49aa12bd565bb614988862351615ad27063cb647f94b8c6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "43de1d1ba52ad631c9976519ca580ac839503f1189d6c232abb81d2724b13d82",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "549e14a9ad48100241c289a7b8660edc8dfc9d03ff65466cfe6850a9f6012e0a",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "57e0ec04a7b0ca48ca8885ad4f8744df046f10d71a64017c48abdbcd349054b4",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7262f7b222381b1c4411f0bd81b2448fe7c46ccc734f8ad90d5e9d7134241e6d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "8acc4ae8995b85711fe6b544b21f80799f86f33621bd1cfebdbf42e9feb958e0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "93ee8bc2953d392d19ad185b9bcf0ba00d78cb36e53a2b1636cd58e5007ae420",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "aea94dbd1d70c346e67fdc1762eb41934259c92a78304855b2bd777a5f5dbb37",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "b3df107471b8b5990050be2a61dc897bb79af2664b595b5aa8220fc3accd1913",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cc4aebd93a7a806b3ea1a19b18d3ba721807d66771b1c2696f03c15fb84e1fd0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "e4114948e72e91c7d434dab8364b79b17adbfac875968f7ae9992d3b70a994a6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "f50f997348768b01ec0943ac0064ebbeda73261f1ed07abd98e31cb995cf0d6a",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class TroveManagerContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    add_trove_owner_to_array: FunctionFragment;
    apply_pending_rewards: FunctionFragment;
    batch_liquidate_troves: FunctionFragment;
    close_trove: FunctionFragment;
    decrease_trove_coll: FunctionFragment;
    decrease_trove_debt: FunctionFragment;
    get_current_icr: FunctionFragment;
    get_entire_debt_and_coll: FunctionFragment;
    get_entire_system_debt: FunctionFragment;
    get_nominal_icr: FunctionFragment;
    get_pending_asset_rewards: FunctionFragment;
    get_pending_usdf_rewards: FunctionFragment;
    get_trove_coll: FunctionFragment;
    get_trove_debt: FunctionFragment;
    get_trove_owner_by_index: FunctionFragment;
    get_trove_owners_count: FunctionFragment;
    get_trove_rewards_snapshot: FunctionFragment;
    get_trove_stake: FunctionFragment;
    get_trove_status: FunctionFragment;
    has_pending_rewards: FunctionFragment;
    increase_trove_coll: FunctionFragment;
    increase_trove_debt: FunctionFragment;
    initialize: FunctionFragment;
    liquidate: FunctionFragment;
    redeem_collateral_from_trove: FunctionFragment;
    remove_stake: FunctionFragment;
    set_trove_status: FunctionFragment;
    update_stake_and_total_stakes: FunctionFragment;
    update_trove_reward_snapshots: FunctionFragment;
  };
}

export class TroveManagerContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: TroveManagerContractInterface;
  declare functions: {
    add_trove_owner_to_array: InvokeFunction<[id: IdentityInput], BN>;
    apply_pending_rewards: InvokeFunction<[id: IdentityInput], void>;
    batch_liquidate_troves: InvokeFunction<[borrowers: Vec<IdentityInput>, upper_partial_hint: IdentityInput, lower_partial_hint: IdentityInput], void>;
    close_trove: InvokeFunction<[id: IdentityInput], void>;
    decrease_trove_coll: InvokeFunction<[id: IdentityInput, value: BigNumberish], BN>;
    decrease_trove_debt: InvokeFunction<[id: IdentityInput, value: BigNumberish], BN>;
    get_current_icr: InvokeFunction<[id: IdentityInput, price: BigNumberish], BN>;
    get_entire_debt_and_coll: InvokeFunction<[id: IdentityInput], [BN, BN, BN, BN]>;
    get_entire_system_debt: InvokeFunction<[], BN>;
    get_nominal_icr: InvokeFunction<[id: IdentityInput], BN>;
    get_pending_asset_rewards: InvokeFunction<[id: IdentityInput], BN>;
    get_pending_usdf_rewards: InvokeFunction<[address: IdentityInput], BN>;
    get_trove_coll: InvokeFunction<[id: IdentityInput], BN>;
    get_trove_debt: InvokeFunction<[id: IdentityInput], BN>;
    get_trove_owner_by_index: InvokeFunction<[index: BigNumberish], IdentityOutput>;
    get_trove_owners_count: InvokeFunction<[], BN>;
    get_trove_rewards_snapshot: InvokeFunction<[id: IdentityInput], RewardSnapshotOutput>;
    get_trove_stake: InvokeFunction<[id: IdentityInput], BN>;
    get_trove_status: InvokeFunction<[id: IdentityInput], StatusOutput>;
    has_pending_rewards: InvokeFunction<[id: IdentityInput], boolean>;
    increase_trove_coll: InvokeFunction<[id: IdentityInput, coll: BigNumberish], BN>;
    increase_trove_debt: InvokeFunction<[id: IdentityInput, debt: BigNumberish], BN>;
    initialize: InvokeFunction<[borrow_operations: ContractIdInput, sorted_troves: ContractIdInput, oracle: ContractIdInput, stability_pool: ContractIdInput, default_pool: ContractIdInput, active_pool: ContractIdInput, coll_surplus_pool: ContractIdInput, usdf_contract: ContractIdInput, asset_contract: AssetIdInput, protocol_manager: ContractIdInput], void>;
    liquidate: InvokeFunction<[id: IdentityInput, upper_partial_hint: IdentityInput, lower_partial_hint: IdentityInput], void>;
    redeem_collateral_from_trove: InvokeFunction<[borrower: IdentityInput, max_usdf_amount: BigNumberish, price: BigNumberish, partial_redemption_hint: BigNumberish, upper_partial_hint: IdentityInput, lower_partial_hint: IdentityInput], SingleRedemptionValuesOutput>;
    remove_stake: InvokeFunction<[id: IdentityInput], void>;
    set_trove_status: InvokeFunction<[id: IdentityInput, status: StatusInput], void>;
    update_stake_and_total_stakes: InvokeFunction<[id: IdentityInput], BN>;
    update_trove_reward_snapshots: InvokeFunction<[id: IdentityInput], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}
