/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.1
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type ReadStorageInput = { f_usdf: BigNumberish, total_fpt_staked: BigNumberish, protocol_manager_address: ContractIdInput, borrower_operations_address: ContractIdInput, fpt_asset_id: AssetIdInput, usdf_asset_id: AssetIdInput, is_initialized: boolean };
export type ReadStorageOutput = { f_usdf: BN, total_fpt_staked: BN, protocol_manager_address: ContractIdOutput, borrower_operations_address: ContractIdOutput, fpt_asset_id: AssetIdOutput, usdf_asset_id: AssetIdOutput, is_initialized: boolean };
export type StakeEventInput = { user: IdentityInput, amount: BigNumberish };
export type StakeEventOutput = { user: IdentityOutput, amount: BN };
export type UnstakeEventInput = { user: IdentityInput, amount: BigNumberish };
export type UnstakeEventOutput = { user: IdentityOutput, amount: BN };

export type FptStakingContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 2
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct events::StakeEvent",
      "concreteTypeId": "971df1a13fb4c3294748768bc549421c23aeb95fa70c9dd0b0e980a61a31a27e",
      "metadataTypeId": 3
    },
    {
      "type": "struct events::UnstakeEvent",
      "concreteTypeId": "f205d319828fe2d29c62ce1d869346d4291ff46474697924876953866d3ea0ec",
      "metadataTypeId": 4
    },
    {
      "type": "struct libraries::fpt_staking_interface::ReadStorage",
      "concreteTypeId": "904c0572d468eff971625d1128ab78e0d942635e2518ea1694091b0617301697",
      "metadataTypeId": 5
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 7
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 8
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "bool",
      "metadataTypeId": 1
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "Address",
          "typeId": 6
        },
        {
          "name": "ContractId",
          "typeId": 8
        }
      ]
    },
    {
      "type": "struct events::StakeEvent",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "user",
          "typeId": 2
        },
        {
          "name": "amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::UnstakeEvent",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "user",
          "typeId": 2
        },
        {
          "name": "amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct libraries::fpt_staking_interface::ReadStorage",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "f_usdf",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "total_fpt_staked",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "protocol_manager_address",
          "typeId": 8
        },
        {
          "name": "borrower_operations_address",
          "typeId": 8
        },
        {
          "name": "fpt_asset_id",
          "typeId": 7
        },
        {
          "name": "usdf_asset_id",
          "typeId": 7
        },
        {
          "name": "is_initialized",
          "typeId": 1
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "asset_address",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "add_asset",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Adds a new asset to the staking contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the protocol manager, called in the `register_asset` fn"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param asset_address The AssetId of the new asset to be added"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:access-control Protocol Manager only"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "asset_address",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "get_pending_asset_gain",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Retrieves the pending asset gain for a specific user and asset"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Calculates the unrealized asset rewards for the given user and asset"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param id The Identity of the user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param asset_address The AssetId of the asset to check"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return The amount of pending asset gain for the user"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_pending_usdf_gain",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Retrieves the pending USDF gain for a specific user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Calculates the unrealized USDF rewards for the given user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param id The Identity of the user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return The amount of pending USDF gain for the user"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_staking_balance",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Retrieves the staking balance for a specific user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Returns the amount of FPT tokens staked by the given user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param id The Identity of the user"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return The staking balance of the user in FPT tokens"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_storage",
      "output": "904c0572d468eff971625d1128ab78e0d942635e2518ea1694091b0617301697",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Retrieves the current storage state of the FPT Staking contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Returns a ReadStorage struct containing key contract parameters and state variables"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return ReadStorage A struct containing f_usdf, total_fpt_staked, protocol_manager_address,"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            "         borrower_operations_address, fpt_asset_id, usdf_asset_id, and is_initialized"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset_fee_amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "asset_address",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "increase_f_asset",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Increases the F_Asset value for a specific asset based on the asset fee amount"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the Protocol Manager contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev If total FPT staked is greater than 0, calculates and adds asset fee per FPT staked"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param asset_fee_amount The amount of asset fee to be distributed"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param asset_address The AssetId of the asset for which the fee is being distributed"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "usdf_fee_amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "increase_f_usdf",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Increases the F_USDF value based on USDF fee amount"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the Borrower Operations contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev If total FPT staked is greater than 0, calculates and adds USDF fee per FPT staked"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param usdf_fee_amount The amount of USDF fee to be distributed"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "protocol_manager_address",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "borrower_operations_address",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "fpt_asset_id",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "usdf_asset_id",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Initializes the FPT Staking contract with essential addresses and tokens"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called once, sets up the contract for staking operations"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param protocol_manager_address The address of the protocol manager contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param borrower_operations_address The address of the borrower operations contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param fpt_asset_id The asset ID of the FPT token"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param usdf_asset_id The asset ID of the USDF token"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "stake",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Allows users to stake their FPT tokens"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Handles staking, updates user's stake, distributes pending rewards, and updates snapshots"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:payable This function is payable and expects FPT tokens to be sent with the transaction"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "unstake",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Allows users to unstake their FPT tokens"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Handles unstaking, updates user's stake, distributes pending rewards, and updates snapshots"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param amount The amount of FPT tokens to unstake"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "logId": "10889125148912567081",
      "concreteTypeId": "971df1a13fb4c3294748768bc549421c23aeb95fa70c9dd0b0e980a61a31a27e"
    },
    {
      "logId": "17439577238580224722",
      "concreteTypeId": "f205d319828fe2d29c62ce1d869346d4291ff46474697924876953866d3ea0ec"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 42872
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "04af14dd7e7552021a0c571f2bc3d630b8573237f5a0e71ea149ec9a07a8953c",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "33f8f7776334dd800aeea5db5aca424ec6ca05483172bf8873f8ad3292120d39",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "39576aa09f6010edc65debe65de5b35739c72fc7691cc32cc2458abde7db8bb7",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "63e660a66ceaf90a9cde9704db18efd7963141df564627f001022b338d5366d6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "75131726e9198400aa9def25b9fc295acd93020ebd33e348aa70c8214855ab79",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cf227e666ed3074e15eeb2923304091f467211295519917b0ba3f1dfd12c6207",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d5264bd45ffc91b221aa51081e7c7d55049195dde6c256ffaf2e0b5539c99ddf",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "eca2959efe775b20f9c13d203a60c6694c50c9bbcfd3cf559c31f3d9f43f5003",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class FptStakingContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    add_asset: FunctionFragment;
    get_pending_asset_gain: FunctionFragment;
    get_pending_usdf_gain: FunctionFragment;
    get_staking_balance: FunctionFragment;
    get_storage: FunctionFragment;
    increase_f_asset: FunctionFragment;
    increase_f_usdf: FunctionFragment;
    initialize: FunctionFragment;
    stake: FunctionFragment;
    unstake: FunctionFragment;
  };
}

export class FptStakingContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: FptStakingContractInterface;
  declare functions: {
    add_asset: InvokeFunction<[asset_address: AssetIdInput], void>;
    get_pending_asset_gain: InvokeFunction<[id: IdentityInput, asset_address: AssetIdInput], BN>;
    get_pending_usdf_gain: InvokeFunction<[id: IdentityInput], BN>;
    get_staking_balance: InvokeFunction<[id: IdentityInput], BN>;
    get_storage: InvokeFunction<[], ReadStorageOutput>;
    increase_f_asset: InvokeFunction<[asset_fee_amount: BigNumberish, asset_address: AssetIdInput], void>;
    increase_f_usdf: InvokeFunction<[usdf_fee_amount: BigNumberish], void>;
    initialize: InvokeFunction<[protocol_manager_address: ContractIdInput, borrower_operations_address: ContractIdInput, fpt_asset_id: AssetIdInput, usdf_asset_id: AssetIdInput], void>;
    stake: InvokeFunction<[], void>;
    unstake: InvokeFunction<[amount: BigNumberish], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}
