import Cookies from "js-cookie";
import { BN } from "fuels";
import { Network } from "../types/fluid.types";

export const CACHE_TIMEOUT = 10 * 60 * 1000; // 10 minutes
export const PRICE_CACHE_TIMEOUT = 60 * 1000; // 60 seconds
export const FPT_PRICE_CACHE_TIMEOUT = 30 * 60 * 1000; // 30 minutes
export const TOTAL_USDF_CACHE_TIMEOUT = 30 * 60 * 1000; // 30 minutes

export const getCachedValue = (
  key: string,
  network: Network,
  customTimeout?: number
): BN | null => {
  const networkKey = `${network}_${key}`;
  const cached = Cookies.get(networkKey);
  if (!cached) return null;

  try {
    const { value, timestamp } = JSON.parse(cached);
    const timeout = customTimeout || CACHE_TIMEOUT;
    if (Date.now() - timestamp > timeout) {
      Cookies.remove(networkKey);
      return null;
    }
    return new BN(value);
  } catch {
    return null;
  }
};

export const setCachedValue = (
  key: string,
  value: BN,
  network: Network
): void => {
  const networkKey = `${network}_${key}`;
  Cookies.set(
    networkKey,
    JSON.stringify({
      value: value.toString(),
      timestamp: Date.now(),
    }),
    { expires: 1 }
  );
};
