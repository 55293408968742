/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.1
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type RedstoneConfigInput = { contract_id: ContractIdInput, price_id: BigNumberish, precision: BigNumberish };
export type RedstoneConfigOutput = { contract_id: ContractIdOutput, price_id: BN, precision: number };

export type OracleContractConfigurables = Partial<{
  PYTH: ContractIdInput;
  PYTH_PRICE_ID: string;
  FUEL_DECIMAL_REPRESENTATION: BigNumberish;
  DEBUG: boolean;
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "b256",
      "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
    },
    {
      "type": "bool",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 0
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct libraries::oracle_interface::RedstoneConfig",
      "concreteTypeId": "a3b17c334355db1b129a4da0bb23f4f6e1176ee4ec168e24a69c591dcacbe5a1",
      "metadataTypeId": 1
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 3
    },
    {
      "type": "u32",
      "concreteTypeId": "d7649d428b9ff33d188ecbf38a7e4d8fd167fa01b2e10fe9a8f9308e52f1d7cc"
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 0,
      "components": [
        {
          "name": "Address",
          "typeId": 2
        },
        {
          "name": "ContractId",
          "typeId": 3
        }
      ]
    },
    {
      "type": "struct libraries::oracle_interface::RedstoneConfig",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "contract_id",
          "typeId": 3
        },
        {
          "name": "price_id",
          "typeId": 4
        },
        {
          "name": "precision",
          "typeId": "d7649d428b9ff33d188ecbf38a7e4d8fd167fa01b2e10fe9a8f9308e52f1d7cc"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "bits",
          "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "bits",
          "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        }
      ]
    },
    {
      "type": "u256",
      "metadataTypeId": 4
    }
  ],
  "functions": [
    {
      "inputs": [],
      "name": "get_price",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "timestamp",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "set_debug_timestamp",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "config",
          "concreteTypeId": "a3b17c334355db1b129a4da0bb23f4f6e1176ee4ec168e24a69c591dcacbe5a1"
        }
      ],
      "name": "set_redstone_config",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "PYTH",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "offset": 22960
    },
    {
      "name": "PYTH_PRICE_ID",
      "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b",
      "offset": 22992
    },
    {
      "name": "FUEL_DECIMAL_REPRESENTATION",
      "concreteTypeId": "d7649d428b9ff33d188ecbf38a7e4d8fd167fa01b2e10fe9a8f9308e52f1d7cc",
      "offset": 22912
    },
    {
      "name": "DEBUG",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
      "offset": 22904
    },
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 22920
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "9e39cce573e16c4eb034ec33f6641ef797822070b45760ef0edafbb57a145b1d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "b9d5c5855929d5adbf4c9ea9e9c162ad8713922326610afa556aeef0052b8614",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d4a9177f9763223a6d1c728f8ca708b50650b415325f831c981294a7fc342cdf",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d4a9177f9763223a6d1c728f8ca708b50650b415325f831c981294a7fc342ce0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "d4a9177f9763223a6d1c728f8ca708b50650b415325f831c981294a7fc342ce1",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class OracleContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    get_price: FunctionFragment;
    set_debug_timestamp: FunctionFragment;
    set_redstone_config: FunctionFragment;
  };
}

export class OracleContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: OracleContractInterface;
  declare functions: {
    get_price: InvokeFunction<[], BN>;
    set_debug_timestamp: InvokeFunction<[timestamp: BigNumberish], void>;
    set_redstone_config: InvokeFunction<[config: RedstoneConfigInput], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}
