import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle } = createMultiStyleConfigHelpers(cardAnatomy.keys);

export const CardStyles = {
  variants: {
    darkCard: definePartsStyle({
      container: {
        w: "100%",
        alignItems: "center",
        borderRadius: 15,
        backgroundColor: "rgba(17, 17, 17, 0.66)",
        position: "relative",
        _before: {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 15,
          background:
            "linear-gradient(to bottom right, rgba(255,255,255,0.3), transparent, transparent)," +
            "linear-gradient(to top left, rgba(255,255,255,0.3), transparent, transparent)",
          mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          maskComposite: "exclude",
          WebkitMaskComposite: "xor",
          padding: "1px",
          pointerEvents: "none",
        },
      },
    }),
  },
};
