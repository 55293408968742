import {
  Modal,
  ModalOverlay,
  ModalContent,
  ButtonGroup,
  Button,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  InputLeftElement,
  InputGroup,
  Tooltip,
} from "@chakra-ui/react";
import { BN } from "@fuel-ts/math";
import { useState, useEffect } from "react";
import { useBorrowOperations } from "../../hooks/BorrowOperationsHook";
import { TrovePosition, useFluid } from "../../hooks/FluidProvider";
import { parseBN, PRECISION } from "../../shared/format";
import { MIN_COLLATERAL_RATIO_DECIMAL, MIN_DEBT } from "../../shared/constants";
import { IAssetContext } from "../../types/fluid.types";

export const AdjustDebtModal = ({
  isOpen,
  onClose,
  trove,
  assetContext,
  index,
}: {
  isOpen: boolean;
  onClose: () => void;
  trove: TrovePosition;
  assetContext: IAssetContext;
  index: number;
}) => {
  const { usdfBalance } = useFluid();
  const { borrowUsdfFromTrove, repayUsdfToTrove, isLoading } =
    useBorrowOperations();
  const [tab, setTab] = useState<"borrow" | "repay">("borrow");
  const [usdfFee, setUsdfFee] = useState<BN>(new BN(0));
  const [debtInput, setDebtInput] = useState<BN>(new BN(0));
  const [debtInputText, setDebtInputText] = useState<string>("");

  const collateralRatio = !trove.debt.isZero()
    ? trove.coll.mul(100).mul(assetContext.price).div(trove.debt).div(PRECISION)
    : new BN(1);

  const proposedDebt =
    tab === "borrow"
      ? trove.debt.add(debtInput).add(usdfFee)
      : trove.debt.sub(debtInput);

  const proposedCollateralRatio = !proposedDebt.isZero()
    ? trove.coll
        .mul(100)
        .mul(assetContext.price)
        .div(proposedDebt)
        .div(PRECISION)
    : new BN(1);

  const liquidationPrice = !collateralRatio.isZero()
    ? assetContext.price.mul(MIN_COLLATERAL_RATIO_DECIMAL).div(collateralRatio)
    : new BN(0);
  const proposedLiquidationPrice = !proposedCollateralRatio.isZero()
    ? assetContext.price
        .mul(MIN_COLLATERAL_RATIO_DECIMAL)
        .div(proposedCollateralRatio)
    : new BN(0);

  function getMax(): BN {
    if (tab === "borrow") {
      return getMaxBorrowable();
    }
    // When repaying, ensure we don't go below MIN_NET_DEBT
    const maxRepayment = trove.debt.sub(MIN_DEBT);
    return BN.min(usdfBalance, maxRepayment);
  }

  function getMaxBorrowable(): BN {
    if (collateralRatio.lte(MIN_COLLATERAL_RATIO_DECIMAL - 1)) {
      return new BN(0);
    }
    return trove.debt
      .mul(collateralRatio)
      .div(MIN_COLLATERAL_RATIO_DECIMAL - 1)
      .sub(trove.debt);
  }

  function isValidBorrow(): boolean {
    return debtInput.gt(new BN(0)) && debtInput.lte(getMax());
  }

  function isValidRepay(): boolean {
    return (
      debtInput.gt(new BN(0)) &&
      debtInput.lte(getMax()) &&
      proposedDebt.gte(MIN_DEBT)
    );
  }

  function closeModal() {
    setDebtInput(new BN(0));
    setDebtInputText("");
    onClose();
  }

  useEffect(() => {
    const isValid = debtInput.gt(new BN(0)) && debtInput.lte(getMax());

    if (tab === "borrow" && isValid) {
      getUSDFFee();
    } else {
      setUsdfFee(new BN(0));
    }
  }, [debtInput, tab]); // Updated dependencies

  async function getUSDFFee() {
    if (assetContext.troveManagerContract && debtInput) {
      const newFee = debtInput.mul(new BN(5)).div(new BN(1000));
      setUsdfFee(newFee);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent
        backgroundColor={"rgba(17, 17, 17)"}
        m={4}
        fontFamily={"IBM Plex Mono"}
        fontWeight={"bold"}
      >
        <ButtonGroup w={"100%"}>
          <Button
            borderRadius={"4px 0 0 0"}
            backgroundColor={"bgMediumGrey"}
            w={"100%"}
            borderBottomColor={tab === "borrow" ? "green.200" : "transparent"}
            borderRightColor={"gray.700"}
            borderBottomWidth={2}
            onClick={() => {
              setTab("borrow");
              setDebtInput(new BN(0));
              setDebtInputText("");
            }}
          >
            Borrow
          </Button>
          <Button
            ml={"0 !important"}
            borderRadius={"0 4px 0 0"}
            borderBottomColor={tab === "repay" ? "green.200" : "transparent"}
            backgroundColor={"bgMediumGrey"}
            borderBottomWidth={2}
            onClick={() => {
              setTab("repay");
              setDebtInput(new BN(0));
              setDebtInputText("");
            }}
            w={"100%"}
          >
            Repay
          </Button>
        </ButtonGroup>
        <ModalBody>
          <VStack mt={2}>
            <VStack w="100%">
              {" "}
              <InputGroup size="md">
                <Input
                  backgroundColor={"bgDarkGrey"}
                  textAlign={"right"}
                  placeholder="250 USDF"
                  isInvalid={!isValidBorrow()}
                  fontSize={"xl"}
                  value={debtInputText}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();
                    if (
                      inputValue.includes(".") &&
                      inputValue.split(".")[1]?.length > 9
                    ) {
                      return;
                    }
                    setDebtInputText(e.target.value);

                    const isValidNumber = /^-?\d*(\.\d{0,9})?$/.test(
                      inputValue
                    );
                    if (isValidNumber) {
                      const parsedValue = parseFloat(inputValue);
                      setDebtInput(new BN(parsedValue * PRECISION));
                    }
                  }}
                />
                {tab === "repay" && (
                  <InputLeftElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="xs"
                      px={4}
                      onClick={() => {
                        setDebtInput(getMax());
                        setDebtInputText(parseBN(getMax(), 9));
                      }}
                    >
                      MAX
                    </Button>
                  </InputLeftElement>
                )}
              </InputGroup>
              <Text
                my={"0 !important"}
                pr={3}
                textAlign={"end"}
                width={"100%"}
                fontSize={"sm"}
                color={"textSecondary"}
              >
                {tab === "borrow"
                  ? "Max Borrowable: " + parseBN(getMax(), 9) + " USDF"
                  : "Balance: " + parseBN(usdfBalance, 9) + " USDF"}
              </Text>
            </VStack>
            <Table size={"sm"}>
              <Tbody>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Debt
                  </Td>
                  <Td
                    borderColor={"transparent"}
                    isNumeric
                    color={
                      tab === "repay" && !isValidRepay() && !debtInput.isZero()
                        ? "orange"
                        : ""
                    }
                  >
                    <Tooltip
                      isDisabled={proposedDebt.gte(MIN_DEBT)}
                      label={`Trove debt cannot be reduced below ${parseBN(
                        new BN(MIN_DEBT),
                        9
                      )} USDF`}
                      placement="top"
                    >
                      <Text as="span">
                        {parseBN(trove.debt, 9)}
                        {debtInput.gtn(0)
                          ? " → " + parseBN(proposedDebt, 9)
                          : ""}{" "}
                        USDF
                      </Text>
                    </Tooltip>
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Collateral Ratio
                  </Td>
                  <Td
                    borderColor={"transparent"}
                    isNumeric
                    color={
                      tab === "borrow" &&
                      !isValidBorrow() &&
                      !debtInput.isZero()
                        ? "orange"
                        : ""
                    }
                  >
                    {parseBN(collateralRatio, 0)}
                    {debtInput.gtn(0)
                      ? " → " + parseBN(proposedCollateralRatio, 0) + "%"
                      : "%"}
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Liquidation Price
                  </Td>
                  <Td borderColor={"transparent"} isNumeric>
                    {parseBN(
                      liquidationPrice,
                      9,
                      liquidationPrice.lt(PRECISION) ? 3 : 2
                    )}
                    {debtInput.gtn(0)
                      ? " → " +
                        parseBN(
                          proposedLiquidationPrice,
                          9,
                          proposedLiquidationPrice.lt(PRECISION) ? 3 : 2
                        )
                      : ""}{" "}
                    USD
                  </Td>
                </Tr>
                <Tr>
                  <Td color={"textSecondary"} borderColor={"transparent"}>
                    Collateral
                  </Td>
                  <Td borderColor={"transparent"} isNumeric>
                    {parseBN(trove.coll, 9)} &nbsp;
                    {assetContext.symbol}
                  </Td>
                </Tr>
                {tab === "borrow" && (
                  <Tr>
                    <Td color={"textSecondary"} borderColor={"transparent"}>
                      Fee
                    </Td>
                    <Td borderColor={"transparent"} isNumeric>
                      {parseBN(usdfFee, 9)} USDF
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Tooltip
            isDisabled={tab !== "repay" || proposedDebt.gte(MIN_DEBT)}
            label={`Trove debt cannot be reduced below ${parseBN(
              new BN(MIN_DEBT),
              9
            )} USDF`}
            placement="top"
          >
            <Button
              width={"100%"}
              backgroundColor={"bgDarkGrey"}
              onClick={
                tab === "borrow"
                  ? () =>
                      borrowUsdfFromTrove(
                        assetContext,
                        debtInput,
                        closeModal,
                        index
                      )
                  : () =>
                      repayUsdfToTrove(
                        assetContext,
                        debtInput,
                        closeModal,
                        index
                      )
              }
              isLoading={isLoading}
              isDisabled={tab === "borrow" ? !isValidBorrow() : !isValidRepay()}
            >
              {tab === "borrow" ? "Borrow" : "Repay"}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
