import {
  Text,
  VStack,
  Button,
  Card,
  CardBody,
  Container,
  HStack,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFluid } from "../../hooks/FluidProvider";
import { useAdminOperations } from "../../hooks/useAdminOperations";

export const AdminCard = () => {
  const { assets } = useFluid();
  const {
    setPauseStatus,
    setPauser,
    setAssetPauseStatus,
    isLoading,
    isPaused,
    pauser,
    assetPauseStatuses,
    transferProtocolManagerOwner,
    protocolManagerOwnerState,
  } = useAdminOperations();

  const [newPauser, setNewPauser] = useState<string>("");
  const [newProtocolOwner, setNewProtocolOwner] = useState<string>("");

  const handleToggle = () => {
    setPauseStatus(!isPaused);
  };

  const handleSetPauser = () => {
    if (newPauser) {
      setPauser(newPauser);
    }
  };

  const handleSetProtocolOwner = () => {
    if (newProtocolOwner) {
      transferProtocolManagerOwner(newProtocolOwner);
    }
  };

  return (
    <Card variant={"darkCard"} fontFamily={"IBM Plex Mono"} fontWeight={"bold"}>
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text
            fontWeight={"semibold"}
            alignSelf={"start"}
            fontFamily={"IBM Plex Mono"}
            fontStyle={"bold"}
          >
            Contract Administration
          </Text>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Manage protocol pause status. When paused, users cannot perform
                any operations that increase the protocol's debt, but they can
                still repay debt and close troves.
              </Text>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
                pt={4}
              >
                <Text color={"textSecondary"}>Protocol Status</Text>
                <Button
                  onClick={handleToggle}
                  isLoading={isLoading}
                  colorScheme={isPaused ? "green" : "red"}
                >
                  {isPaused ? "Unpause Protocol" : "Pause Protocol"}
                </Button>
              </HStack>
              <Text fontSize={"sm"} color={"textSecondary"}>
                {isPaused
                  ? "Protocol is currently paused"
                  : "Protocol is currently active"}
              </Text>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Current Pauser: {pauser}
              </Text>
              <HStack w={"100%"}>
                <Input
                  placeholder="Enter new pauser address"
                  value={newPauser}
                  onChange={(e) => setNewPauser(e.target.value)}
                  flex={1}
                />
                <Button
                  onClick={handleSetPauser}
                  isLoading={isLoading}
                  minW={"120px"}
                >
                  Set Pauser
                </Button>
              </HStack>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Asset Pause Status Overview
              </Text>

              <Table variant="simple" size="sm" w="100%">
                <Thead>
                  <Tr>
                    <Th>Asset</Th>
                    <Th>Status</Th>
                    <Th textAlign="right">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assets.map((asset) => (
                    <Tr key={asset.contractIds.assetId}>
                      <Td>{asset.symbol}</Td>
                      <Td>
                        {assetPauseStatuses[asset.contractIds.assetId]
                          ? "Paused"
                          : "Active"}
                      </Td>
                      <Td textAlign="right">
                        <Button
                          size="sm"
                          onClick={() =>
                            setAssetPauseStatus(
                              asset.contractIds.assetId,
                              !assetPauseStatuses[asset.contractIds.assetId]
                            )
                          }
                          isLoading={isLoading}
                          colorScheme={
                            assetPauseStatuses[asset.contractIds.assetId]
                              ? "green"
                              : "red"
                          }
                        >
                          {assetPauseStatuses[asset.contractIds.assetId]
                            ? "Unpause"
                            : "Pause"}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Protocol Manager Ownership
              </Text>
              <Text fontSize={"sm"} color={"textSecondary"}>
                Current Owner State: {protocolManagerOwnerState?.owner}
              </Text>
              <HStack w={"100%"}>
                <Input
                  placeholder="Enter new owner address"
                  value={newProtocolOwner}
                  onChange={(e) => setNewProtocolOwner(e.target.value)}
                  flex={1}
                />
                <Button
                  onClick={handleSetProtocolOwner}
                  isLoading={isLoading}
                  minW={"120px"}
                >
                  Transfer Owner
                </Button>
              </HStack>
            </VStack>
          </Container>
        </VStack>
      </CardBody>
    </Card>
  );
};
