import { Box, Flex, Text } from "@chakra-ui/react";

export function Footer() {
  return (
    <Box color="textSecondary" mt={4}>
      <Box
        h="1px"
        mx="auto"
        my={2}
        bgImage="linear-gradient(to right, transparent, #00FF0040, #00FF0060, #00FF0040, transparent)"
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p={4}
        pb={0}
        mx="auto"
      >
        <Text fontSize="sm">© 2025 Fluid Protocol. All rights reserved.</Text>
        <Flex gap={2}>
          <Text
            as="a"
            href="/privacy-policy"
            fontSize="sm"
            color="textSecondary"
            _hover={{ color: "white", textDecoration: "underline" }}
          >
            Privacy Policy
          </Text>
          <Text fontSize="sm" color="textSecondary">
            |
          </Text>
          <Text
            as="a"
            href="/terms-of-service"
            fontSize="sm"
            color="textSecondary"
            _hover={{ color: "white", textDecoration: "underline" }}
          >
            Terms of Service
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
