import axios from "axios";

export async function fetchOpenTroves(account: string, isTestnet: boolean) {
  if (isTestnet) {
    return new Map<string, boolean>([["0x1", true]]);
  }

  // Convert account to lowercase to match the stored format
  const normalizedAccount = account.toLowerCase();

  const query = `query {
    BorrowOperations_OpenTroveEvent(
      where: {identity: {_eq: "${normalizedAccount}"}},
      order_by: {timestamp: desc}
    ) {
      asset
      identity
      timestamp
      collateral
      debt
      txHash
    }
  }`
    .replace(/\s+/g, " ")
    .trim();

  try {
    const response = await axios.post(
      "https://stats.fluidprotocol.xyz/v1/graphql",
      {
        query,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      }
    );

    const openTrovesMap = new Map<string, boolean>();
    if (response.data?.data) {
      const { BorrowOperations_OpenTroveEvent: openEvents } =
        response.data.data;
      openEvents.forEach((event: any) => {
        openTrovesMap.set(event.asset.toLowerCase(), true);
      });
    }

    return openTrovesMap;
  } catch (error) {
    console.error("Error fetching open troves:", error);
    // Create a Map subclass that defaults to true for any key
    return new (class extends Map<string, boolean> {
      get(key: string) {
        return true;
      }
    })();
  }
}
