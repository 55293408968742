import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { CardStyles } from "./cardStyles";
import { TextStyles } from "./textStyles";

import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const greenPrimary = defineStyle({
  color: "white",
  background: "green.500",
  fontWeight: "extrabold",
  fontSize: "1rem",
  fontFamily: "IBM Plex Mono",
  // let's also provide dark mode alternatives
  _dark: {
    background: "green.700",
    color: "white",

    _hover: {
      background: "green.500",
      color: "white",
    },
  },
});

export const buttonTheme = defineStyleConfig({
  variants: { greenPrimary },
});

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
    cssVarPrefix: "fluid",
    disableTransitionOnChange: false,
    storageKey: "fluid-color-mode",
    storageManager: {
      get: (key: string) => {
        let value = localStorage.getItem(key);
        if (!value) {
          localStorage.setItem(key, "dark");
          return "dark";
        }
        return value;
      },
      set: (key: string, value: string) => {
        localStorage.setItem(key, value);
      },
    },
  },
  colors: {
    textPrimary: "#ffffff",
    textSecondary: "#9ea6b1",
    bgDarkGrey: "#202020",
    bgLightGrey: "rgba(255, 255, 255, 0.08)",
    bgLightGreyNotTransparent: "#424141",
    bgMediumGrey: "#313131",
  },
  components: {
    Card: CardStyles,
    Text: TextStyles,
    Button: buttonTheme,
  },

  styles: {
    global: (props: any) => ({
      "html, body": {
        fontFamily: "body",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "black")(props),
        transitionProperty: "background-color",
        transitionDuration: "normal",
        lineHeight: "base",
      },
    }),
  },
});
