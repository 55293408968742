import { ChakraProvider } from "@chakra-ui/react";
import { FluidProvider } from "./hooks/FluidProvider";
import AppRoutes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./styling/theme";
import { FuelProvider } from "@fuels/react";
import {
  FueletWalletConnector,
  BakoSafeConnector,
  FuelWalletConnector,
  WalletConnectConnector,
  SolanaConnector,
} from "@fuels/connectors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { coinbaseWallet, walletConnect } from "@wagmi/connectors";
import { http, createConfig, injected } from "@wagmi/core";
import { mainnet, sepolia } from "@wagmi/core/chains";
import { CHAIN_IDS, FuelConnector, Provider } from "fuels";
import { Analytics } from "@vercel/analytics/react";

const queryClient = new QueryClient();

// ============================================================
// WalletConnect Connector configurations
// https://docs.walletconnect.com/web3modal/javascript/about
// ============================================================
const UI_CONFIG = {
  suggestBridge: false,
};
const WC_PROJECT_ID = "7fc8402a198551e4bd808ec43855cd89";
const METADATA = {
  name: "Fluid Protocol",
  description: "Fluid Protocol wallet connection",
  url: window.location.href,
  icons: ["https://connectors.fuel.network/logo_white.png"],
};
const NETWORKS = [
  {
    bridgeURL: "https://app-testnet.fuel.network/bridge?from=eth&to=fuel",
    chainId: CHAIN_IDS.fuel.testnet,
  },
  {
    bridgeURL: "https://app-mainnet.fuel.network/bridge?from=eth&to=fuel",
    chainId: CHAIN_IDS.fuel.mainnet,
  },
];
const provider = Provider.create("https://mainnet.fuel.network/v1/graphql", {
  headers: {
    "Content-Type": "application/json",
    Origin: window.location.origin,
  },
});
const wagmiConfig = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
  connectors: [
    injected({ shimDisconnect: false }),
    walletConnect({
      projectId: WC_PROJECT_ID,
      metadata: METADATA,
      showQrModal: false,
    }),
    coinbaseWallet({
      appName: METADATA.name,
      appLogoUrl: METADATA.icons[0],
      darkMode: true,
      reloadOnDisconnect: true,
    }),
  ],
});

const customDefaultConnectors = (): Array<FuelConnector> => {
  const connectors: Array<FuelConnector> = [
    new BakoSafeConnector(),
    new FuelWalletConnector(),
    new FueletWalletConnector(),

    new WalletConnectConnector({
      projectId: WC_PROJECT_ID,
      wagmiConfig: wagmiConfig,
      chainId: CHAIN_IDS.fuel.mainnet,
      fuelProvider: provider,
    }),
    new SolanaConnector({
      projectId: WC_PROJECT_ID,
      chainId: CHAIN_IDS.fuel.mainnet,
      fuelProvider: provider,
    }),
  ];

  return connectors;
};

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FuelProvider
        theme="dark"
        uiConfig={UI_CONFIG}
        networks={NETWORKS}
        fuelConfig={{
          connectors: customDefaultConnectors(),
        }}
      >
        <Analytics />
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <FluidProvider>
              <AppRoutes />
            </FluidProvider>
          </ChakraProvider>
        </BrowserRouter>
      </FuelProvider>
    </QueryClientProvider>
  );
};
