/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.1
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Vec } from "./common";

export type PriceInput = { confidence: BigNumberish, exponent: BigNumberish, price: BigNumberish, publish_time: BigNumberish };
export type PriceOutput = { confidence: BN, exponent: number, price: BN, publish_time: BN };

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "(b256, struct pyth_interface::data_structures::price::Price)",
      "concreteTypeId": "8af0b6871d3069e02766459f479b5f87d25716b57c505664c964523acf74a607",
      "metadataTypeId": 0
    },
    {
      "type": "b256",
      "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct pyth_interface::data_structures::price::Price",
      "concreteTypeId": "8aba92fff7345309d4313706ed7db3a811609f62da8f0d2859819db43d461ff8",
      "metadataTypeId": 3
    },
    {
      "type": "struct std::vec::Vec<(b256, struct pyth_interface::data_structures::price::Price)>",
      "concreteTypeId": "988159d50c80300126a35f8e4012cbf97f2196003396bdbe5592bb426af1b5cd",
      "metadataTypeId": 5,
      "typeArguments": [
        "8af0b6871d3069e02766459f479b5f87d25716b57c505664c964523acf74a607"
      ]
    }
  ],
  "metadataTypes": [
    {
      "type": "(_, _)",
      "metadataTypeId": 0,
      "components": [
        {
          "name": "__tuple_element",
          "typeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        },
        {
          "name": "__tuple_element",
          "typeId": 3
        }
      ]
    },
    {
      "type": "generic T",
      "metadataTypeId": 1
    },
    {
      "type": "raw untyped ptr",
      "metadataTypeId": 2
    },
    {
      "type": "struct pyth_interface::data_structures::price::Price",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "confidence",
          "typeId": 7
        },
        {
          "name": "exponent",
          "typeId": 6
        },
        {
          "name": "price",
          "typeId": 7
        },
        {
          "name": "publish_time",
          "typeId": 7
        }
      ]
    },
    {
      "type": "struct std::vec::RawVec",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "ptr",
          "typeId": 2
        },
        {
          "name": "cap",
          "typeId": 7
        }
      ],
      "typeParameters": [
        1
      ]
    },
    {
      "type": "struct std::vec::Vec",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "buf",
          "typeId": 4,
          "typeArguments": [
            {
              "name": "",
              "typeId": 1
            }
          ]
        },
        {
          "name": "len",
          "typeId": 7
        }
      ],
      "typeParameters": [
        1
      ]
    },
    {
      "type": "u32",
      "metadataTypeId": 6
    },
    {
      "type": "u64",
      "metadataTypeId": 7
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "price_feed_id",
          "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        }
      ],
      "name": "price",
      "output": "8aba92fff7345309d4313706ed7db3a811609f62da8f0d2859819db43d461ff8",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "price_feed_id",
          "concreteTypeId": "7c5ee1cecf5f8eacd1284feb5f0bf2bdea533a51e2f0c9aabe9236d335989f3b"
        }
      ],
      "name": "price_unsafe",
      "output": "8aba92fff7345309d4313706ed7db3a811609f62da8f0d2859819db43d461ff8",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "feeds",
          "concreteTypeId": "988159d50c80300126a35f8e4012cbf97f2196003396bdbe5592bb426af1b5cd"
        }
      ],
      "name": "update_price_feeds",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    }
  ],
  "messagesTypes": [],
  "configurables": []
};

const storageSlots: StorageSlot[] = [];

export class MockPythContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    price: FunctionFragment;
    price_unsafe: FunctionFragment;
    update_price_feeds: FunctionFragment;
  };
}

export class MockPythContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: MockPythContractInterface;
  declare functions: {
    price: InvokeFunction<[price_feed_id: string], PriceOutput>;
    price_unsafe: InvokeFunction<[price_feed_id: string], PriceOutput>;
    update_price_feeds: InvokeFunction<[feeds: Vec<[string, PriceInput]>], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}
