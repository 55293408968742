import { Box, Spacer, Hide } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Footer } from "./Footer";
import { TitleBar } from "./TitleBar";
import FluidBg from "../../images/fluidbg.png";
import { SideBar } from "./SideBar";

export const PageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      textAlign="center"
      fontSize="xl"
      minH={"100vh"}
      justifyContent={"space-between"}
      backgroundImage={FluidBg}
      backgroundSize={"cover"}
      backgroundPosition={["left", "right"]}
      display={"flex"}
      flexDirection={"column"}
      pb={[0, 8]}
      pt={[0, 2]}
      pl={[2, "calc(250px + 1rem)"]}
      pr={[2, 4, 4, 12]}
    >
      <Hide below="sm">
        <SideBar />
      </Hide>
      <TitleBar />
      <Box mt={[0, "3%"]}>{children}</Box>
      <Spacer />
      <Footer />
    </Box>
  );
};
